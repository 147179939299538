import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import { FormTextRegular } from '../../../components/form/FormText';
import Button from '../../../common/components/Button';
import { INTEGRATION_INSPECT_REAL_ESTATE } from '../../../constants/featureFlags';

import styles from './InspectRealEstateIntegration.module.scss';
import Icon, { Icons } from '../../../common/components/Icon';

interface InspectRealEstateIntegrationProps {
    agencyId: string;
}

const InspectRealEstateIntegration: React.FC<InspectRealEstateIntegrationProps> = ({ agencyId }) => {
    const isInspectRealEstateIntegrationActive = useFeatureFlag(INTEGRATION_INSPECT_REAL_ESTATE);

    const [showCopySuccessful, setShowCopySuccessful] = useState(false);

    const [showAgency, setShowAgency] = useState(false);

    const [showConnectionInstruction, setShowConnectionInstruction] = useState(false);

    const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        navigator.clipboard.writeText(agencyId).then(() => setShowCopySuccessful(true));
    };

    const handleIntegrateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setShowAgency(true);
    };

    return isInspectRealEstateIntegrationActive ? (
        <Form initialValues={{ agencyId }} onSubmit={() => {}}>
            {() => {
                return (
                    <form>
                        <div className={`panel ${styles.container}`}>
                            <h2 className="panel-header">Inspect Real Estate (2Apply)</h2>
                            <div className="panel-body">
                                {showAgency && (
                                    <>
                                        <div className={styles.agency}>
                                            <FormTextRegular label="Agency ID" name="agencyId" disabled={true} />
                                            <Button
                                                className={`${styles.copyButton} ${
                                                    showCopySuccessful ? styles.success : ''
                                                }`}
                                                onClick={handleCopyClick}
                                            >
                                                {showCopySuccessful ? 'Copied!' : 'Copy'}
                                            </Button>
                                        </div>

                                        <div className={styles.question}>
                                            <Icon icon={Icons.QUESTION_MARK} />
                                            <a
                                                onClick={() => {
                                                    setShowConnectionInstruction(true);
                                                }}
                                                className="special-link"
                                            >
                                                Connection instructions
                                            </a>
                                        </div>

                                        {showConnectionInstruction && (
                                            <div className={styles.instructions}>
                                                <ol>
                                                    <li>Login to InspectRealEstate</li>
                                                    <li>
                                                        In the left side menu, go to Settings {'>'} General {'>'} Forms
                                                        Integration
                                                    </li>
                                                    <li>
                                                        Select Tenancy Agreements for Leases in the drop down at the top
                                                        of the page
                                                    </li>
                                                    <li>
                                                        In the list of providers on the left side, click FLK it over
                                                    </li>
                                                    <li>
                                                        Copy the above Agency ID then paste it into the text box
                                                        labelled ‘AgencyID’ then click Connect.
                                                    </li>
                                                </ol>
                                            </div>
                                        )}
                                    </>
                                )}
                                <Button primary className="integrations-button-primary" onClick={handleIntegrateClick}>
                                    Integrate
                                </Button>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    ) : null;
};

export default InspectRealEstateIntegration;
