import React from 'react';
import cx from 'classnames';
import Card from './Card';
import Button from '../Button.js';
import styles from './ExpandableCard.module.scss';
import { ExpandMore } from '@material-ui/icons';
// Workaround for using latest framer-motion with webpack v4 - can be removed once upgrade to v5 is complete
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

const ExpandButton: React.FC<{
    handleExpand: () => void;
    label: string;
    ariaExpanded: boolean;
}> = ({ handleExpand, label, ariaExpanded }) => {
    return (
        <Button onClick={handleExpand} className={styles.toggle} aria-expanded={ariaExpanded}>
            {label}
            <ExpandMore className={styles.toggleIcon} />
        </Button>
    );
};

type ExpandableCardProps = {
    className?: string;
    header: React.ReactNode;
    /** Content shown in collapsed state */
    collapsedContent?: React.ReactNode;
    /** Additional content for expanded state */
    expandedContent: React.ReactNode;
    footer?: React.ReactNode;
    expanded?: boolean;
    /** Props to control the expand button */
    expandButtonProps?: {
        collapsedLabel: string;
        expandedLabel: string;
        handleExpand: () => void;
    };
    testId?: string;
};

/** A card that can expand to show additonal content.
    Expandable cards can be placed in a group (e.g. on the signup page). In this situation the primary card will control the
    expansion of all other cards in the group.
 */
const ExpandableCard: React.FC<ExpandableCardProps> = ({
    className,
    header,
    collapsedContent,
    expandedContent,
    footer,
    expanded,
    expandButtonProps,
    testId
}) => {
    return (
        <Card
            animated
            className={cx(styles.expandableCard, className, expanded ? styles.expanded : null)}
            testId={testId}
        >
            <motion.div layout="position">{header}</motion.div>
            {/*This div is here to contain the body of the card in a single row */}
            <div>
                <motion.div layout="position">{collapsedContent}</motion.div>
                <AnimatePresence>
                    {expanded && (
                        <motion.div
                            layout
                            transition={{
                                layout: {
                                    duration: 0.05
                                }
                            }}
                            initial={{ opacity: 0, height: 0 }}
                            animate={{
                                opacity: 1,
                                height: 'auto',
                                transition: {
                                    opacity: {
                                        delay: 0.2,
                                        duration: 0.15
                                    },
                                    height: {
                                        duration: 0.2
                                    }
                                }
                            }}
                            exit={{
                                opacity: 0,
                                height: 0,
                                transition: {
                                    opacity: {
                                        duration: 0.05
                                    },
                                    height: {
                                        delay: 0.05,
                                        duration: 0.15
                                    }
                                }
                            }}
                        >
                            {expandedContent}
                        </motion.div>
                    )}
                </AnimatePresence>

                {expandButtonProps && (
                    <ExpandButton
                        label={expanded ? expandButtonProps.expandedLabel : expandButtonProps.collapsedLabel}
                        handleExpand={expandButtonProps.handleExpand}
                        ariaExpanded={!!expanded}
                    />
                )}
            </div>
            <motion.div layout="position">{footer}</motion.div>
        </Card>
    );
};

export default ExpandableCard;
