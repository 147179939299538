import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmFees } from '../../../../selectors/lease/pmLease';

import { FormNumber, FormTextRegular } from '../../../../components/form/FormText';
import { FormButtonSelect } from '../../../../components/form/responsive/FormButtonSelect';
import { FeesGroup } from '../../../../components/lease/mainScreen/common/pm/FeesGroup';
import { INCLUDING_GST, gstList } from '../../../../config';
import '../../../../sass/pmFees.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';
import { getAgency } from '../../../../selectors/user';

const PM_FEES_FORM = 'pmFeesForm';

function getDefaultState(agency) {
    if (agency && agency.pmFees) {
        return Object.assign(initState, agency.pmFees);
    } else {
        return initState;
    }
}

const initState = {
    initialLeasingFee: '$',
    leaseRenewalFee: '$',
    managementFee: '',
    leasePreparationFee: '$',
    adminFee: '$',
    initialLeasingFeeIncGST: INCLUDING_GST,
    leaseRenewalFeeIncGST: INCLUDING_GST,
    managementFeeIncGST: INCLUDING_GST,
    leasePreparationFeeIncGST: INCLUDING_GST,
    adminFeeIncGST: INCLUDING_GST,
    additionalFeesList: []
};

const PriceAndFee = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmFees = useSelector(getPmFees);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const agency = useSelector(getAgency);

    const [pmFees, setPmFees] = useState(reduxPmFees || getDefaultState(agency));

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_FEES_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmFees(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmFees')) {
                    return error.response.data.errors.pmFees;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmFees = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-fees`, data);
    };

    const removeFee = (values, index) => {
        let newFees = cloneDeep(values);

        newFees.additionalFeesList = [
            ...newFees.additionalFeesList.slice(0, index),
            ...newFees.additionalFeesList.slice(index + 1)
        ];

        setPmFees(newFees);
    };

    return (
        <div className="form-container">
            <Form
                onSubmit={submitForm}
                initialValues={pmFees}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_FEES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="pmFees">
                                <div className="nowrap fee-item">
                                    <FormNumber name="managementFee" label="Management fee %" required />
                                    <FormButtonSelect
                                        label="GST"
                                        name="managementFeeIncGST"
                                        selectClassName="gst-select"
                                        options={gstList}
                                        value={values.managementFeeIncGST}
                                    />
                                </div>
                                <div className="nowrap fee-item">
                                    <FormTextRegular name="initialLeasingFee" label="Leasing fee" required />
                                    <FormButtonSelect
                                        label="GST"
                                        name="initialLeasingFeeIncGST"
                                        selectClassName="gst-select"
                                        options={gstList}
                                        value={values.initialLeasingFeeIncGST}
                                    />
                                </div>
                                <div className="nowrap fee-item">
                                    <FormTextRegular name="leaseRenewalFee" label="Lease renewal fee" required />
                                    <FormButtonSelect
                                        label="GST"
                                        name="leaseRenewalFeeIncGST"
                                        selectClassName="gst-select"
                                        options={gstList}
                                        value={values.leaseRenewalFeeIncGST}
                                    />
                                </div>
                                <div className="nowrap fee-item">
                                    <FormTextRegular name="adminFee" label="Monthly admin fee " required />
                                    <FormButtonSelect
                                        label="GST"
                                        name="adminFeeIncGST"
                                        selectClassName="gst-select"
                                        options={gstList}
                                        value={values.adminFeeIncGST}
                                    />
                                </div>
                                <div className="nowrap fee-item">
                                    <FormTextRegular
                                        name="leasePreparationFee"
                                        label="Lease preparation fee"
                                        required
                                    />
                                    <FormButtonSelect
                                        label="GST"
                                        name="leasePreparationFeeIncGST"
                                        selectClassName="gst-select"
                                        options={gstList}
                                        value={values.leasePreparationFeeIncGST}
                                    />
                                </div>
                                <FieldArray name="additionalFeesList" initialValue={pmFees.additionalFeesList}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <FeesGroup
                                                        index={index}
                                                        key={index}
                                                        removeFee={index => removeFee(values, index)}
                                                        location={location}
                                                        fee={fields.value[index]}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                                <div className="button">
                                    <button
                                        className="mobile add-item"
                                        type="button"
                                        onClick={() =>
                                            push('additionalFeesList', {
                                                description: '',
                                                amount: '$',
                                                incGST: INCLUDING_GST
                                            })
                                        }
                                    >
                                        <img src={plusIcon} className="for-sm-modal" />
                                        Add Fees
                                    </button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(PriceAndFee);
