import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import Panel from '../../../common/components/Panel';
import { FormTextRegular } from '../../../components/form/FormText';
import { tabs, HIDE_MESSAGE_TIME, isPaymentMethodAvailable, LEASE_TYPE_RESIDENTIAL } from '../../../config';
import { getResTenLabel } from '../../../utils/labelUtils';
import { editPermissions } from '../../../utils/userPermissions';
import { updateDefaultPaymentDetails } from '../../../actions/payments';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import '../../../sass/payment.scss';
import { useEffect } from 'react';
import { getUserInfo } from '../../../selectors/user';
import { getAgency } from '../../../selectors/settings/account';
import { getAccount, getPaymentTemplatesToUpdate } from '../../../selectors/settings/account';
import ConfirmUpdateTemplates from '../UpdateTemplatesConfirmation';
import { confirmAlert } from 'react-confirm-alert';
import { clearTemplatesToUpdate } from '../../../actions/account';
import { updateUsedTemplates } from '../../../actions/template';
import SaveButtonIcon from '../SaveButtonIcon';

const DefaultSettingsPayments = () => {
    const LEASE_PATH = 'paymentMethod';
    const dispatch = useDispatch();

    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getAgency);
    const account = useSelector(getAccount);
    const defaultPaymentsTemplatesToUpdate = useSelector(getPaymentTemplatesToUpdate);

    const clearTemplates = () => {
        dispatch(clearTemplatesToUpdate());
    };

    const updateTemplates = () => {
        defaultPaymentsTemplatesToUpdate.leasePath = LEASE_PATH;
        dispatch(updateUsedTemplates(defaultPaymentsTemplatesToUpdate));
    };

    useEffect(() => {
        if (
            defaultPaymentsTemplatesToUpdate &&
            defaultPaymentsTemplatesToUpdate.templates &&
            defaultPaymentsTemplatesToUpdate.templates.length > 0
        ) {
            confirmAlert({
                customUI: ConfirmUpdateTemplates(
                    defaultPaymentsTemplatesToUpdate,
                    clearTemplates,
                    updateTemplates,
                    'PAYMENT DETAILS'
                ),
                closeOnEscape: false,
                closeOnClickOutside: false
            });
        }
    }, [defaultPaymentsTemplatesToUpdate]);

    const submit = (agencyId, values, form) => {
        // set dirty Fields
        values.dirtyFields = { ...form.getState().dirtyFields, leasePath: LEASE_PATH };
        dispatch(updateDefaultPaymentDetails(agencyId, values));
    };

    const getIsPending = () => {
        return account.UPDATE_PAYMENT_EFT_REQUEST_PENDING;
    };

    const getIsSaved = () => {
        return account.UPDATE_PAYMENT_EFT_REQUEST_SUCCESS;
    };

    const getIsSaveFailed = () => {
        return account.UPDATE_PAYMENT_EFT_REQUEST_FAIL;
    };

    let userRole = loggedInUser.role;
    const isPending = getIsPending();
    const savedSuccessfully = getIsSaved();
    const saveFailed = getIsSaveFailed();

    return (
        <div className="settings">
            <div className="payment">
                <Form
                    onSubmit={() => {}}
                    initialValues={agency.paymentMethod}
                    validate={props => {
                        let errors = account.validationErrors;
                        if (!errors) {
                            return;
                        }
                        return account.validationErrors.paymentMethod;
                    }}
                >
                    {({ handleSubmit, values, form }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <Panel
                                    title="Default payment details"
                                    rightButton={
                                        <div>
                                            {editPermissions(userRole, tabs.TAB_PAYMENTS) && (
                                                <SaveButtonIcon
                                                    isSaving={isPending}
                                                    success={savedSuccessfully}
                                                    failure={saveFailed}
                                                    onClick={() => submit(agency.id, values, form)}
                                                />
                                            )}
                                        </div>
                                    }
                                >
                                    <p className="help-text">Select payment method(s) and fill payment details.</p>
                                    {agency?.details?.location ? (
                                        <>
                                            {isPaymentMethodAvailable(
                                                'DirectDebit',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck name="DirectDebit.enabled" label="Direct Debit" />
                                                    {values.DirectDebit && values.DirectDebit.enabled && (
                                                        <div className="details">
                                                            <p className="name">Details for Direct Debit</p>
                                                            <FormTextRegular
                                                                name="DirectDebit.details.comments"
                                                                label="Comment"
                                                            />
                                                            <div>
                                                                <CheckboxCheck
                                                                    className={'permission-to-debit-invoices'}
                                                                    name="DirectDebit.details.permissionToDebitInvoices"
                                                                    label="Permission to debit invoices"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'EFT',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck
                                                        name="EFT.enabled"
                                                        label={getResTenLabel('eft', agency.details.location)}
                                                    />
                                                    {values.EFT && values.EFT.enabled && (
                                                        <div className="details">
                                                            <p className="name">
                                                                Details for{' '}
                                                                {getResTenLabel('eft', agency.details.location)}
                                                            </p>
                                                            <FormTextRegular
                                                                name="EFT.details.accountName"
                                                                label="Account name"
                                                                required
                                                            />
                                                            <FormTextRegular
                                                                name="EFT.details.accountNumber"
                                                                label="Account number"
                                                                required
                                                            />
                                                            <FormTextRegular
                                                                name="EFT.details.bankName"
                                                                label="Bank name"
                                                                required
                                                            />
                                                            <FormTextRegular
                                                                name="EFT.details.BSB"
                                                                label="BSB"
                                                                required
                                                            />
                                                            <FormTextRegular
                                                                name="EFT.details.reference"
                                                                label="Reference"
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'BPAY',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck name="BPAY.enabled" label="BPAY" />
                                                    {values.BPAY && values.BPAY.enabled && (
                                                        <div className="details">
                                                            <p className="name">Details for BPAY</p>
                                                            <FormTextRegular
                                                                name="BPAY.details.billerCode"
                                                                label="Biller Code"
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'Cheque',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck name="Cheque.enabled" label="Cheque" />
                                                    {values.Cheque && values.Cheque.enabled && (
                                                        <div className="details">
                                                            <p className="name">Details for Cheque</p>
                                                            <FormTextRegular
                                                                name="Cheque.details.madePayableTo"
                                                                label="Made payable to"
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'Cash',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck name="Cash.enabled" value={true} label="Cash" />
                                                    {values.Cash && values.Cash.enabled && (
                                                        <div className="details">
                                                            <p className="name">Details for Cash</p>
                                                            <FormTextRegular
                                                                name="Cash.details"
                                                                label="Details"
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'ChequeOrCash',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck name="ChequeOrCash.enabled" label="Cheque Or Cash" />
                                                    {values.ChequeOrCash && values.ChequeOrCash.enabled && (
                                                        <div className="details">
                                                            <p className="name">Details for Cheque Or Cash</p>
                                                            <FormTextRegular
                                                                name="ChequeOrCash.details.payableTo"
                                                                label="Payable to"
                                                            />
                                                            <FormTextRegular
                                                                name="ChequeOrCash.details.address"
                                                                label="Address"
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'MoneyOrder',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck
                                                        name="MoneyOrder.enabled"
                                                        value={true}
                                                        label="MoneyOrder"
                                                    />
                                                    {values.MoneyOrder && values.MoneyOrder.enabled && (
                                                        <div className="details">
                                                            <p className="name">Details for Money Order</p>
                                                            <FormTextRegular
                                                                name="MoneyOrder.details"
                                                                label="Details"
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {isPaymentMethodAvailable(
                                                'Other',
                                                LEASE_TYPE_RESIDENTIAL,
                                                agency.details.location
                                            ) && (
                                                <React.Fragment>
                                                    <CheckboxCheck
                                                        name="Other.enabled"
                                                        value={true}
                                                        label={getResTenLabel('other', agency.details.location)}
                                                    />
                                                    {values.Other && values.Other.enabled && (
                                                        <div className="details">
                                                            <p className="name">
                                                                Details for{' '}
                                                                {getResTenLabel('other', agency.details.location)}
                                                            </p>
                                                            <FormTextRegular
                                                                name="Other.details"
                                                                label="Details"
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </>
                                    ) : (
                                        <div className="blue-spinner-inline" />
                                    )}
                                    {account.error && <div className="payment-error">{account.error}</div>}
                                </Panel>
                            </form>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
};

export default DefaultSettingsPayments;
