import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPmExpensesAndCharges } from '../../../selectors/lease/pmLease';

import { ChargesGroup } from '../../../components/lease/mainScreen/common/pm/ChargesGroup';
import { FormTextRegular } from '../../../components/form/FormText';
import '../../../sass/expenses.scss';
import { ReactComponent as plusIcon } from '../../../../assets/images/icons/plus.svg';

const PM_EXPENSES_CHARGES_FORM = 'pmExpensesAndChargesForm';
const initState = {
    chargeList: [],
    advertising: '$',
    limitOnCostsOfRepairs: '$',
    photography: '$',
    bankCharges: '$',
    financialYearSummary: '$',
    payable: '$'
};

const ExpensesAndCharges = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmExpensesAndCharges = useSelector(getPmExpensesAndCharges);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmExpensesAndCharges, setPmExpensesAndCharges] = useState(reduxPmExpensesAndCharges || initState);

    useEffect(() => {
        if (reduxPmExpensesAndCharges) {
            const clonedExpensesAndCharges = cloneDeep(reduxPmExpensesAndCharges);
            if (!clonedExpensesAndCharges.limitOnCostsOfRepairs) {
                clonedExpensesAndCharges.limitOnCostsOfRepairs = '$';
            }
            if (!clonedExpensesAndCharges.photography) {
                clonedExpensesAndCharges.photography = '$';
            }
            if (!clonedExpensesAndCharges.bankCharges) {
                clonedExpensesAndCharges.bankCharges = '$';
            }
            if (!clonedExpensesAndCharges.financialYearSummary) {
                clonedExpensesAndCharges.financialYearSummary = '$';
            }
            if (!clonedExpensesAndCharges.payable) {
                clonedExpensesAndCharges.payable = '$';
            }
            setPmExpensesAndCharges(clonedExpensesAndCharges);
        }
    }, [reduxPmExpensesAndCharges]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_EXPENSES_CHARGES_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmExpensesAndCharges(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmExpensesAndCharges')) {
                    return error.response.data.errors.pmExpensesAndCharges;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmExpensesAndCharges = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-expenses-and-charges`, data);
    };

    const removeCharge = (values, index) => {
        let newCharges = cloneDeep(values);

        newCharges.chargeList = [...newCharges.chargeList.slice(0, index), ...newCharges.chargeList.slice(index + 1)];
        setPmExpensesAndCharges(newCharges);
    };

    return (
        <div className="expenses">
            <Form
                onSubmit={submitForm}
                initialValues={pmExpensesAndCharges}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    values,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_EXPENSES_CHARGES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                {location === 'NSW' && (
                                    <div>
                                        <FormTextRegular name="advertising" label="Advertising" required />
                                        <FormTextRegular
                                            name="limitOnCostsOfRepairs"
                                            label="Limit On Costs Of Repairs"
                                        />
                                        <FormTextRegular name="photography" label="Photography " />
                                        <FormTextRegular name="bankCharges" label="Bank Charges " />
                                        <FormTextRegular name="financialYearSummary" label="Financial Year Summary" />
                                    </div>
                                )}
                                {location === 'QLD' && (
                                    <div className="formBox">
                                        <FormTextRegular name="advertising" label="Advertising" required />
                                        <FormTextRegular name="payable" label="Payable " />
                                    </div>
                                )}
                                <FieldArray name="chargeList" initialValue={pmExpensesAndCharges.chargeList}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <ChargesGroup
                                                        index={index}
                                                        key={`${index}`}
                                                        removeCharge={index => removeCharge(values, index)}
                                                        location={location}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                                <div className="button">
                                    <button
                                        className="mobile add-item"
                                        type="button"
                                        onClick={() =>
                                            push('chargeList', {
                                                description: '',
                                                amount: '$'
                                            })
                                        }
                                    >
                                        <img src={plusIcon} className="for-sm-modal" />
                                        Add Charge
                                    </button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(ExpensesAndCharges);
