import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getSignatoryReducer } from '../../../selectors/lease/salesLease';

import SignatoryPersonGroup from '../../../components/lease/mainScreen/common/sales/SignatoryPersonGroup';
import '../../../sass/signatory.scss';
import { ReactComponent as plusIcon } from '../../../../assets/images/icons/plus.svg';
import { getLabel } from '../../../utils/labelUtils';
import { LEASE_TYPE_SALES } from '../../../config';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';

const maxSignatories = 4;

const SIGNATORY_FORM = 'signatoryForm';

const Signatory = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxSignatoryReducer = useSelector(getSignatoryReducer);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [signatory, setSignatory] = useState(reduxSignatoryReducer || {});
    const [primaryVendorError, setPrimaryVendorError] = useState('');

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(SIGNATORY_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        setPrimaryVendorError('');
        return updateSignatory(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SIGNATORY_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.signatory')) {
                    if (error.response.data.errors.signatory.isPrimaryVendor) {
                        setPrimaryVendorError(error.response.data.errors.signatory.isPrimaryVendor);
                    }
                    return error.response.data.errors.signatory;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateSignatory = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/signatory`, data);
    };

    const removeSignatoryPerson = (values, index) => {
        const data = cloneDeep(values);
        if (data.signatories[index].isPrimaryVendor) {
            data.signatories[0].isPrimaryVendor = true;
        }
        data.signatories = [...data.signatories.slice(0, index), ...data.signatories.slice(index + 1)];
        setSignatory(data);
    };

    const handlePrimaryVendorSelect = (values, index, value) => {
        let newState = cloneDeep(values);
        let length = newState.signatories.length;
        if (!value) {
            newState.signatories[0].isPrimaryVendor = true;
            if (index !== 0) {
                newState.signatories[index].isPrimaryVendor = false;
            }
        } else {
            for (let i = 0; i < length; i++) {
                newState.signatories[i].isPrimaryVendor = index === i;
            }
        }
        setSignatory({
            ...newState
        });
    };

    return (
        <div className="signatory">
            <Form
                onSubmit={submitForm}
                initialValues={signatory}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    values,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={SIGNATORY_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="persons">
                                {primaryVendorError && (
                                    <p className="FormInput">
                                        <span className="FormError">{primaryVendorError}</span>
                                    </p>
                                )}
                                <FieldArray name="signatories" initialValue={signatory.signatories}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <SignatoryPersonGroup
                                                        key={index}
                                                        index={index}
                                                        removePerson={index => removeSignatoryPerson(values, index)}
                                                        handlePrimaryVendorSelect={(index, value) => {
                                                            handlePrimaryVendorSelect(values, index, value);
                                                        }}
                                                        vendor={fields.value[index]}
                                                        form={form}
                                                        location={location}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </div>
                            {signatory && signatory.signatories && signatory.signatories.length >= maxSignatories ? (
                                <div className="add-landlord">
                                    <p>A maximum of four vendors may be added to the agreement</p>
                                </div>
                            ) : (
                                <div className="button">
                                    <button
                                        className="mobile add-item"
                                        type="button"
                                        onClick={() =>
                                            push('signatories', {
                                                firstName: '',
                                                secondName: ''
                                            })
                                        }
                                    >
                                        <img src={plusIcon} className="for-sm-modal" />
                                        Add {getLabel('tenantLower', location, LEASE_TYPE_SALES)}
                                    </button>
                                </div>
                            )}
                            <CheckboxCheck
                                name="salesIdentificationRequired"
                                label={getLabel('identityImages', location, LEASE_TYPE_SALES)}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Signatory);
