import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getValidationErrors, getLeaseType } from '../../../../selectors/lease';
import { getPmLandlord } from '../../../../selectors/lease/pmLease';
import { getLabel } from '../../../../utils/labelUtils';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';

import { FormTextRegular } from '../../../../components/form/FormText';
import { FormEmail } from '../../../../components/form/FormEmail';
import { FormPhone } from '../../../../components/form/FormPhone';
import { FormMaskedText } from '../../../../components/form/FormText';
import { FormButtonSelect } from '../../../../components/form/responsive/FormButtonSelect';

import { LEASE_TYPE_PROPERTY_MANAGEMENT, corporationRolesList } from '../../../../config';

import '../../../../sass/signatory.scss';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';

const maxLandlords = 6;
const PM_LANDLORD_FORM = 'pmLandlordForm';
const initState = {
    persons: [{ firstName: '', secondName: '', isPrimaryLandlord: true }]
};

function Client(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const validationErrors = useSelector(getValidationErrors);
    const reduxPmLandlordReducer = useSelector(getPmLandlord);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmLandlord, setPmLandlord] = useState(reduxPmLandlordReducer || initState);

    useEffect(() => {
        if (!pmLandlord?.persons?.length > 0) {
            setPmLandlord({
                ...pmLandlord,
                persons: initState.persons
            });
        }
    }, []);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_LANDLORD_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const removeLandlordPerson = (values, index) => {
        let newState = cloneDeep(values);
        if (newState.persons[index].isPrimaryLandlord) {
            newState.persons[0].isPrimaryLandlord = true;
        }
        setPmLandlord({
            ...newState,
            persons: [...newState.persons.slice(0, index), ...newState.persons.slice(index + 1)]
        });
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        let pmLandlordValues = cloneDeep(values);
        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-landlord`, pmLandlordValues)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmLandlord')) {
                    return error.response.data.errors.pmLandlord;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const handlePrimaryLandlordSelect = (values, index, value) => {
        let newState = cloneDeep(values);
        let length = newState.persons.length;
        if (!value) {
            newState.persons[0].isPrimaryLandlord = true;
            if (index !== 0) {
                newState.persons[index].isPrimaryLandlord = false;
            }
        } else {
            for (let i = 0; i < length; i++) {
                newState.persons[i].isPrimaryLandlord = index === i;
            }
        }
        setPmLandlord({
            ...newState
        });
    };

    return (
        <div className="signatory">
            <Form
                onSubmit={submitForm}
                initialValues={pmLandlord}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form,
                    form: {
                        mutators: { push }
                    },
                    values
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_LANDLORD_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="persons">
                                {validationErrors &&
                                    validationErrors.pmLandlord &&
                                    validationErrors.pmLandlord.isPrimaryLandlord && (
                                        <p className="FormInput">
                                            <span className="FormError">
                                                {validationErrors.pmLandlord.isPrimaryLandlord}
                                            </span>
                                        </p>
                                    )}
                                <FieldArray name="persons" initialValue={pmLandlord.persons}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) =>
                                                renderPersons(values, fields.value[index], name, index)
                                            )}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </div>
                            <div className="button">
                                {pmLandlord && pmLandlord.persons && pmLandlord.persons.length >= maxLandlords ? (
                                    <div className="add-landlord">
                                        <p>A maximum of six clients may be added to the agreement</p>
                                    </div>
                                ) : (
                                    <button
                                        className="mobile add-item"
                                        type="button"
                                        onClick={() =>
                                            push('persons', {
                                                firstName: '',
                                                secondName: '',
                                                isPrimaryLandlord: false
                                            })
                                        }
                                    >
                                        <img src={plusIcon} className="for-sm-modal" />
                                        Add client
                                    </button>
                                )}
                            </div>
                            <CheckboxCheck
                                name="propertyManagementIdentificationRequired"
                                label={getLabel('identityImages', location, LEASE_TYPE_PROPERTY_MANAGEMENT)}
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );

    function renderPersons(values, landlord, name, index) {
        let header;
        let caption = getLabel('landlord', location, LEASE_TYPE_PROPERTY_MANAGEMENT);
        let isCorporation = landlord.isCorporation;
        if (index === 0) {
            header = (
                <h3>
                    {caption} {index + 1}
                </h3>
            );
        } else {
            header = (
                <span className="removePerson">
                    <h3>
                        {caption} {index + 1}
                    </h3>
                    <button type="button" onClick={() => removeLandlordPerson(values, index)}>
                        <span className="span-remove">Remove</span>
                    </button>
                </span>
            );
        }
        return (
            <div className="person">
                {header}
                <div className="formBox">
                    <div className="formBox-column">
                        <CheckboxCheck
                            name={`persons[${index}].isCorporation`}
                            className={`corporation-${index}-check`}
                            label={`Client is a corporation`}
                            value={`persons[${index}].isCorporation`}
                        />
                        {isCorporation && (
                            <React.Fragment>
                                <FormTextRegular
                                    name={`persons[${index}].corporationName`}
                                    label="Company Name"
                                    required
                                />
                                <FormMaskedText name={`persons[${index}].ABN`} label="ABN" mask="11 111 111 111" />
                                <FormMaskedText name={`persons[${index}].ACN`} label="ACN" mask="111 111 111" />
                                <p className="form-field-label">Person signing:</p>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="nowrap fields-full-width-for-mobile">
                        <FormTextRegular name={`persons[${index}].firstName`} label="First Name" required />
                        <FormTextRegular name={`persons[${index}].middleName`} label="Middle Name" />
                    </div>
                    <div className="formBox-column">
                        <FormTextRegular name={`persons[${index}].secondName`} label="Last Name" required />
                        {isCorporation && (
                            <React.Fragment>
                                <FormButtonSelect
                                    name={`persons[${index}].role`}
                                    selectClassName={`landlord-${index}-role`}
                                    options={corporationRolesList}
                                    value={landlord.role}
                                    label="Role of person signing"
                                    isButtonGroup={true}
                                />
                                {landlord.role === 'Other' && (
                                    <FormTextRegular name={`persons[${index}].otherRole`} label="Role name" required />
                                )}
                            </React.Fragment>
                        )}
                        <FormPhone
                            name={`persons[${index}].phone`}
                            label="Phone"
                            className={`persons-${index}-phone`}
                        />
                        <FormPhone
                            name={`persons[${index}].mobile`}
                            label="Mobile"
                            required
                            className={`persons-${index}-mobile`}
                        />
                        <FormEmail name={`persons[${index}].email`} label="Email" required />
                        {!isCorporation && (
                            <FormTextRegular name={`persons[${index}].address`} label="Address" required />
                        )}
                        {isCorporation && (
                            <CheckboxCheck name={`persons[${index}].isRegisteredForGST`} label="Registered for GST" />
                        )}
                        <CheckboxCheck
                            name={`persons[${index}].isPrimaryLandlord`}
                            label="Primary client"
                            onClick={value => handlePrimaryLandlordSelect(values, index, value)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default memo(forwardRef(Client));
