import React from 'react';
import { HubspotProvider, NewRelicProvider } from './IdentityProvider';
import { SmartlookProvider } from './SmartlookProvider';

// This component is used to nest external scripts that we want to add to the page document
// This is mainly done to reduce nesting in the App component
export function ExternalScriptProvider({ children }) {
    return (
        <>
            <HubspotProvider>
                <NewRelicProvider>
                    <SmartlookProvider>{children}</SmartlookProvider>
                </NewRelicProvider>
            </HubspotProvider>
        </>
    );
}
