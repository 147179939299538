import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Signature from './Signature';
import Button from '../../../../common/components/Button';
import appHistory from './../../../../AppHistory';
import { isEmpty } from 'lodash';
import { closeAgreementInfoModalWithoutAnyOtherAction, toggleMobileView } from '../../../../actions/dashboard';
import { getWitnessSignature } from '../../../../actions/completion';
import { PreLoader } from '../../../../common/components/PreLoader';
import Select from 'react-select';
import { customSelectStyle } from '../../../../utils/formUtils';
import {
    getIsGettingAgentSignature,
    getWitnessSignature as getWitnessSignatureFromRedux,
    getAgentSignature,
    getWitness,
    getWitnessSignatureError,
    getIsGettingWitnessSignature,
    getIsGettingWitnessSignatureSuccess
} from '../../../../selectors/completion';
import { getAgreementInfo } from '../../../../selectors/dashboard/agreementInfo';
import { needWitnessSignature } from '../../../../utils/agreementUtils';

const getWitnessOptions = witnesses => {
    const teamWitnesses = [];
    if (witnesses) {
        witnesses.forEach(witness => {
            teamWitnesses.push({
                value: witness.id,
                label: witness.fullName
            });
        });
    }
    return teamWitnesses;
};

export function AwaitingCompletionSignatures() {
    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState('');
    const [password, setPassword] = useState('');
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        password: '',
        selectWitness: ''
    });

    const isGettingWitnessSignature = useSelector(getIsGettingWitnessSignature);
    const isGettingAgentSignature = useSelector(getIsGettingAgentSignature);
    const isGettingWitnessSignatureSuccess = useSelector(getIsGettingWitnessSignatureSuccess);
    const witnessSignature = useSelector(getWitnessSignatureFromRedux);
    const agentSignature = useSelector(getAgentSignature);
    const witnessSignatureError = useSelector(getWitnessSignatureError);
    const lease = useSelector(getAgreementInfo);
    const witnesses = useSelector(getWitness);

    useEffect(() => {
        if (!isGettingWitnessSignature) {
            // If success do not show error, if fail show error
            let showErrors = !isGettingWitnessSignatureSuccess;
            setShowValidationErrors(showErrors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingWitnessSignature]);

    const onCreateSignatureClick = () => {
        dispatch(closeAgreementInfoModalWithoutAnyOtherAction());
        appHistory.push('/user/signatures');
    };

    const goToDesktopView = () => {
        dispatch(toggleMobileView(false));
    };

    const selectOption = selectedOption => {
        setSelectedOption(selectedOption);
    };

    const handlePasswordChange = e => {
        let val = e.target.value;
        setPassword(val);
    };

    const validate = () => {
        setValidationErrors({
            password: isEmpty(password) ? 'Password is required' : '',
            selectWitness: isEmpty(selectedOption) ? 'Witness is required' : ''
        });

        return !isEmpty(password) && !isEmpty(selectedOption);
    };

    const signAsWitness = () => {
        if (!validate()) {
            return;
        }
        setShowValidationErrors(false);

        dispatch(getWitnessSignature(selectedOption.value, password));
    };
    if (isEmpty(agentSignature) && !isGettingAgentSignature) {
        return (
            <div className="no-signature">
                <div className="for-lg-modal">
                    <p>
                        You haven't added your digital signature yet. Please create your signature to have the ability
                        to sign agreements.
                    </p>
                    <Button primary onClick={onCreateSignatureClick}>
                        Create Signature
                    </Button>
                </div>
                <div className="for-sm-modal">
                    <div className="no-signature__card">
                        <h6>Agent signature</h6>
                        <p>
                            You haven't added your digital signature yet. Please create your signature on the desktop
                            version to have the ability to sign agreements.
                        </p>
                        <span className="txt-link" onClick={() => goToDesktopView()}>
                            Go to desktop version
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    let options = getWitnessOptions(witnesses);
    let disabled = isEmpty(options) || isGettingWitnessSignature;
    let placeholder = disabled ? 'No witness available' : 'Select a witness';

    return (
        <div>
            {isEmpty(agentSignature) && isGettingAgentSignature ? (
                <div className="preloader-wrapper">
                    <PreLoader />
                </div>
            ) : (
                <Signature signature={agentSignature} />
            )}
            {needWitnessSignature(lease) &&
                (isEmpty(witnessSignature) ? (
                    <div className="witness-form">
                        <div data-test="witness-name">
                            <Select
                                styles={customSelectStyle}
                                value={selectedOption}
                                clearable={false}
                                options={options}
                                className="selectWitness"
                                onChange={item => selectOption(item)}
                                disabled={disabled}
                                placeholder={placeholder}
                                isSearchable={false}
                                classNamePrefix="react-select"
                            />
                        </div>
                        <div className="has-error">{validationErrors.selectWitness}</div>
                        <div className="FormInput">
                            <div className={password ? 'form-group regular on' : 'form-group regular'}>
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    disabled={disabled}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                        </div>
                        <div className="has-error">{validationErrors.password}</div>
                        {showValidationErrors && <div className="has-error">{witnessSignatureError}</div>}
                        <div className="button">
                            <Button primary onClick={signAsWitness} disabled={disabled} type="submit">
                                Sign as Witness
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Signature signature={witnessSignature} />
                ))}
        </div>
    );
}

export default AwaitingCompletionSignatures;
