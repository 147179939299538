import React from 'react';
import { CLIENT_OTHER, CLIENT_TRADING_NAME } from '../../../../config';
import { getClientTypeFromDoc } from '../../../../utils/documentUtils.js';
import SummaryItem from './SummaryItem';
import { Agency } from '@app/types/Agency';
import { ApiModel } from '../buildADoc/BuildADoc.model';

import styles from './AgentSummary.module.scss';

type CreateAgentSummaryProps = {
    currentDocument: ApiModel;
    type: string;
    agency: Agency;
};

const AgentSummary: React.FC<CreateAgentSummaryProps> = ({ currentDocument, type, agency }) => {
    let clientType = getClientTypeFromDoc(currentDocument, type);
    if ([CLIENT_OTHER, CLIENT_TRADING_NAME].includes(clientType)) {
        clientType = type.toUpperCase();
    }

    const items = [
        {
            value: agency.details.agencyName,
            isBold: true
        },
        {
            value: agency.details.address
        },
        {
            value: agency.details.phone
        },
        {
            value: agency.details.email
        }
    ];
    return (
        <div className={styles.container}>
            <h2>{`${clientType} Agent Details`}</h2>

            <SummaryItem items={items} />
        </div>
    );
};
export default AgentSummary;
