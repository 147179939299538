import { useRef } from 'react';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

// Hook to return a unique id for a component. Can be replaced with the native useId() hook if we upgrade to React 18
// From https://gist.github.com/sqren/fc897c1629979e669714893df966b1b7?permalink_comment_id=3189166#gistcomment-3189166
export const useComponentId = () => {
    const idRef = useRef<number | undefined>(undefined);
    if (idRef.current === undefined) {
        idRef.current = getUniqueId();
    }
    return idRef.current;
};

export default useComponentId;
