import {
    ANSWER_YES,
    CONTACT_TYPE_AUTO_SAVE,
    FormSubmitType,
    PARTY_LANDLORD,
    PARTY_NONE,
    defaultAnswerOptions
} from '../../../../config';
import { array, lazy, object, string, boolean } from 'yup';
import {
    ClientFieldConfig,
    FieldType,
    agentFieldsConfig,
    corporationEntityFieldsConfig,
    corporationLandlordFieldsConfig,
    customEntityFieldsConfig,
    entityFieldsConfig,
    landlordFieldsConfig,
    tradingNameFieldsConfig
} from './BuildADoc.config';
import { capitalize, startCase } from 'lodash';
import { TagColor } from '../../../../common/components/tag/Tag';
import { getClientTagColor, getClientTypeOptions } from './utils';
import { User } from '../../../../types/User';
import { isMobilePhone, isCorrectEmail } from '../../../../components/Validate';
import { ValidationErrors } from 'final-form';
import { DateString, DEFINE_ME } from '@app/types/utilityTypes';

export enum DeliveryType {
    Email = 'email',
    SMS = 'sms'
}

export enum AcknowledgementType {
    Sign = 'sign'
}

const createClientSectionSchema = (deliveryType: DeliveryType) => {
    return lazy(value => {
        const clientType = value.type;
        return object().shape({
            includeLandlordAgentDetails: boolean().notRequired(),
            clients: array(
                object().shape({
                    isPrimary: boolean().notRequired(),
                    isCorporation: boolean().notRequired(),
                    isRemoved: boolean().notRequired(),
                    isOther: boolean().notRequired(),
                    data: lazy((_, context) => {
                        const clientConfig = getClientConfig(clientType, context.parent.isCorporation);

                        return array(
                            object().shape({
                                label: string().required('Label is required'),
                                value: lazy((_, context) => {
                                    const fieldConfig = clientConfig.fields.find(
                                        field => field.label === context.parent.label
                                    );

                                    const fieldType = fieldConfig?.type;

                                    if (fieldType === FieldType.Phone) {
                                        const phoneSchema = string().test(
                                            'isPhone',
                                            'Please enter a valid mobile phone number',
                                            value => isMobilePhone(value, true)
                                        );

                                        const isPhoneRequired =
                                            fieldConfig?.isRequired || deliveryType === DeliveryType.SMS;

                                        return isPhoneRequired
                                            ? phoneSchema.required(`${context.parent.label} is required`)
                                            : phoneSchema.notRequired();
                                    } else if (fieldType === FieldType.Email) {
                                        const emailSchema = string().test(
                                            'isEmail',
                                            'Please enter a valid email',
                                            isCorrectEmail
                                        );

                                        return fieldConfig?.isRequired
                                            ? emailSchema.required(`${context.parent.label} is required`)
                                            : emailSchema.notRequired();
                                    } else {
                                        return fieldConfig?.isRequired
                                            ? string().required(`${context.parent.label} is required`)
                                            : string().notRequired();
                                    }
                                })
                            })
                        );
                    })
                })
            )
        });
    });
};

export const buildADocFormSchema = object().shape({
    deliveryType: string().required('Delivery type is required'),
    acknowledgementType: string().required('Acknowledgement type is required'),
    from: object().when('deliveryType', {
        is: DeliveryType.SMS,
        then: () => createClientSectionSchema(DeliveryType.SMS),
        otherwise: () => createClientSectionSchema(DeliveryType.Email)
    }),
    to: object().when('deliveryType', {
        is: DeliveryType.SMS,
        then: () => createClientSectionSchema(DeliveryType.SMS),
        otherwise: () => createClientSectionSchema(DeliveryType.Email)
    }),
    documentDescription: string().required('Document description is required'),
    questions: array(
        object().shape({
            title: string().required('Title is required'),
            description: string().required('Description is required'),
            answerOptionsSelected: array().when('requireClientAction', {
                is: true,
                then: schema => schema.min(1, 'Answer options are required'),
                otherwise: schema => schema.notRequired()
            }),
            detailsOption: string().when(['answerOptionsSelected', 'requireClientAction'], {
                is: (answerOptionsSelected: string[], requireClientAction: boolean) =>
                    requireClientAction && answerOptionsSelected?.includes('yes'),
                then: schema => schema.required('Details option is required'),
                otherwise: schema => schema.notRequired()
            })
        })
    ),
    clauseTitle: string().required('Clause title is required'),
    acknowledgementText: string().required('Acknowledgement text is required')
});

type Option = {
    answer: string;
    detailsOption?: string;
};

type ClientFieldData = {
    label: string;
    value: string;
    isRemoved?: boolean;
    isOther?: boolean;
    meta: {
        order: number;
        type: FieldType;
    };
};

export type FormModelClient = {
    isPrimary?: boolean;
    isCorporation?: boolean;
    data: ClientFieldData[];
    source?: string;
};

type FormModelClientSection = {
    type: ClientType;
    includeLandlordAgentDetails?: boolean;
    clients: FormModelClient[];
};

export type FormModel = {
    id?: string;
    to: FormModelClientSection;
    from: FormModelClientSection;
    deliveryType?: DeliveryType;
    acknowledgementType?: AcknowledgementType.Sign;
    clauseTitle: string;
    questions: {
        title: string;
        subTitle?: string;
        description: string;
        answerOptionsSelected?: string[];
        requireClientAction?: boolean;
        detailsOption?: string;
        optional?: boolean;
    }[];
    acknowledgementText: string;
    documentDescription: string;
    submitType?: FormSubmitType;
    documentTitle: string;
    templateName?: string;
    documentInstructions?: string;
    meta?: {
        isGlobalTemplate?: boolean;
        isDocumentAsTemplate?: boolean;
    };
    isCreatingGlobalTemplate?: boolean;
};

type ApiModelClient = {
    isCorporation?: boolean;
    isPrimaryClient?: boolean;
    includeLandlordAgentDetails?: boolean;
    data: {
        label: string;
        mandatory: boolean;
        other: boolean;
        mappingFieldName: string;
        order: number;
        prerequisite: Record<string, boolean>[];
        value: string;
        showField?: boolean;
    }[];
    fullName?: string;
    email?: string;
    mobile?: string;
    source?: string;
    id?: string;
    signature?: DEFINE_ME;
};

type ApiModelClientSection = {
    label: string;
    includeLandlordAgentDetails?: boolean;
    clients: ApiModelClient[];
};

export type ApiModel = {
    id?: string;
    to: ApiModelClientSection;
    from: ApiModelClientSection;
    deliveryType?: DeliveryType;
    clauseTitle: string;
    questions: {
        answerOptions: Option[];
        answerOptionsSelected: Option[];
        title: string;
        subTitle?: string;
        description: string;
        optional: boolean;
        questionId: number;
        // TODO (VA): Check following properties with FLK B
        noAgentAnswerQuestion: true;
        partyToAnswer: string;
    }[];
    acknowledgementText: string;
    documentDescription?: string;
    documentTitle: string;
    templateName?: string;
    documentInstructions?: string;
    isGlobalTemplate?: boolean;
    sentForSigning?: DateString;
};

const projectApiModelClient = (client: FormModelClient, clientType: ClientType): ApiModelClient => {
    const clientConfig = getClientConfig(clientType, client.isCorporation);
    return {
        isCorporation: client.isCorporation,
        isPrimaryClient: client.isPrimary,
        data: client.data
            .filter(data => !data.isRemoved)
            .map(data => {
                // FUTURE: We can cut this out by using a name for each field instead of having them as an array
                const fieldConfig = clientConfig.fields.find(field => field.label === data.label);
                return {
                    label: data.label,
                    mandatory: !!fieldConfig?.isRequired,
                    other: !!data.isOther,
                    mappingFieldName: fieldConfig?.mappingName || '',
                    order: data.meta.order,
                    prerequisite: fieldConfig?.prerequisite || [],
                    value: data.value
                };
            }),
        source: client.source
    };
};

export const projectBuildADocApiModel = (values: FormModel): ApiModel => {
    return {
        id: values.id,
        clauseTitle: values.clauseTitle,
        deliveryType: values.deliveryType,
        to: {
            label: startCase(values.to.type),
            includeLandlordAgentDetails: values.to.includeLandlordAgentDetails,
            clients: values.to.clients.map(client => projectApiModelClient(client, values.to.type))
        },
        from: {
            label: startCase(values.from.type),
            includeLandlordAgentDetails: values.from.includeLandlordAgentDetails,
            clients: values.from.clients.map(client => projectApiModelClient(client, values.from.type))
        },
        questions: values.questions?.map((question, index) => ({
            answerOptions: defaultAnswerOptions,
            answerOptionsSelected: defaultAnswerOptions
                .filter(option => question.answerOptionsSelected?.includes(option.name))
                .map(option =>
                    option.answer === ANSWER_YES ? { ...option, detailsOption: question.detailsOption } : option
                ),
            title: question.title,
            subTitle: question.subTitle,
            description: question.description,
            partyToAnswer: question.requireClientAction ? PARTY_LANDLORD : PARTY_NONE,
            optional: !!question.optional,
            // TODO (VA): Look into questionId
            questionId: index + 1,
            noAgentAnswerQuestion: true
        })),
        acknowledgementText: values.acknowledgementText,
        documentDescription: values.documentDescription,
        documentTitle: values.documentTitle,
        templateName: values.templateName,
        documentInstructions: values.documentInstructions,
        isGlobalTemplate: values.isCreatingGlobalTemplate
    };
};

const getInitialFromSection = (
    isBusinessUser: boolean,
    loggedInUser?: User,
    type?: ClientType,
    useAgencyDetails = false
): FormModelClientSection => {
    if (isBusinessUser) {
        const clientType = type || ClientType.TradingName;
        return {
            type: clientType,
            clients: [
                generateNewClient(
                    clientType,
                    ClientSection.From,
                    loggedInUser,
                    useAgencyDetails && clientType === ClientType.TradingName
                )
            ]
        };
    } else {
        const clientType = type || ClientType.Agent;
        return {
            type: clientType,
            clients: [
                generateNewClient(
                    clientType,
                    ClientSection.From,
                    loggedInUser,
                    useAgencyDetails && clientType === ClientType.Agent
                )
            ]
        };
    }
};

const getInitialToSection = (isBusinessUser: boolean): FormModelClientSection => {
    return isBusinessUser
        ? {
              type: ClientType.Other,
              clients: [{ ...generateNewClient(ClientType.Other, ClientSection.To), isPrimary: true }]
          }
        : {
              type: ClientType.Landlord,
              clients: [{ ...generateNewClient(ClientType.Landlord, ClientSection.To), isPrimary: true }]
          };
};

export const initialiseFormModel = (
    loggedInUser: User,
    isBusinessUser: boolean,
    template?: FormModel,
    isCreatingGlobalTemplate?: boolean
): Partial<FormModel> => {
    const { isDocumentAsTemplate, isGlobalTemplate: isUsingGlobalTemplate } = template?.meta || {};

    const shouldUseTemplateFromData =
        template && template.from.type !== ClientType.Agent && template.from.type !== ClientType.TradingName;

    let fromSection: FormModelClientSection;

    if (isCreatingGlobalTemplate) {
        fromSection = getInitialFromSection(isBusinessUser, loggedInUser, template?.from.type, false);
    } else if (isUsingGlobalTemplate) {
        fromSection = getInitialFromSection(isBusinessUser, loggedInUser, template?.from.type, true);
    } else if (shouldUseTemplateFromData) {
        fromSection = template.from;
    } else {
        fromSection = getInitialFromSection(isBusinessUser, loggedInUser, undefined, true);
    }

    return {
        ...template,
        acknowledgementText: template?.acknowledgementText || 'I confirm I have read and reviewed the document',
        from: fromSection,
        to: template?.to ? template.to : getInitialToSection(isBusinessUser),
        questions: template?.questions
            ? template.questions
            : [
                  {
                      title: '',
                      description: ''
                  }
              ],
        templateName: (isDocumentAsTemplate ? template?.documentTitle : template?.templateName) || '',
        isCreatingGlobalTemplate: isCreatingGlobalTemplate,
        id: undefined
    };
};

const getOptionName = (answer: string) => {
    return answer.replace(/ /g, '_').toLowerCase();
};
const projectFormModelClientSection = (
    section: ApiModelClientSection,
    loggedInUser?: User,
    includeAgencyAdditionalDetails = false
): FormModelClientSection => {
    const clientType = section.label.toLowerCase() as ClientType;

    return {
        type: clientType,
        includeLandlordAgentDetails: section.includeLandlordAgentDetails,
        clients: !section.clients.length
            ? [generateNewClient(clientType, ClientSection.To, loggedInUser, true)]
            : section.clients.map((client): FormModelClient => {
                  const clientConfig = getClientConfig(clientType, client.isCorporation);

                  const clientData = clientConfig.fields.map((field): ClientFieldData => {
                      const data = client.data.find(data => data.label === field.label);
                      return {
                          label: field.label,
                          value: data?.value || '',
                          // A removed field will not be present in the API response, but will appear in the config
                          isRemoved: data === undefined,
                          meta: {
                              order: field.order,
                              type: field.type
                          }
                      };
                  });

                  // There are some fields that can be added by the user on the form,
                  // or have been added as part of the agency details (for FLK B)
                  client.data
                      .filter(data => !clientConfig.fields.find(field => field.label === data.label))
                      .forEach(data => {
                          clientData.push({
                              label: data.label,
                              value: data.value,
                              isOther: data.other,
                              isRemoved: false,
                              meta: {
                                  order: clientData.length,
                                  type: FieldType.Text
                              }
                          });
                      });

                  if (includeAgencyAdditionalDetails && loggedInUser?.agency) {
                      // Now we add agency fields that are not present in the client data
                      loggedInUser.agency.details.otherDetails
                          ?.filter(detail => !client.data.find(data => data.label === detail.label))
                          .forEach(detail => {
                              clientData.push({
                                  label: detail.label,
                                  value: detail.value,
                                  isRemoved: true,
                                  meta: {
                                      order: clientData.length,
                                      type: FieldType.Text
                                  }
                              });
                          });
                  }

                  return {
                      isCorporation: client.isCorporation,
                      isPrimary: client.isPrimaryClient,
                      data: clientData
                  };
              })
    };
};

export const projectFormModel = (
    values: ApiModel,
    loggedInUser?: User,
    isBusinessUser?: boolean,
    isGlobalTemplateMode?: boolean
): FormModel => {
    return {
        id: values.id,
        from: isGlobalTemplateMode
            ? getInitialFromSection(
                  !!isBusinessUser,
                  loggedInUser,
                  values.from.label.toLowerCase() as ClientType,
                  false
              )
            : projectFormModelClientSection(values.from, loggedInUser, isBusinessUser),
        to: projectFormModelClientSection(values.to, loggedInUser),
        deliveryType: values.deliveryType,
        acknowledgementType: AcknowledgementType.Sign,
        clauseTitle: values.clauseTitle,
        questions: values.questions?.map(question => ({
            title: question.title,
            subTitle: question.subTitle,
            description: question.description,
            answerOptionsSelected: question.answerOptionsSelected.map(option => getOptionName(option.answer)),
            requireClientAction: question.partyToAnswer === PARTY_LANDLORD,
            detailsOption: question.answerOptionsSelected.find(option => option.answer === ANSWER_YES)?.detailsOption,
            optional: question.optional
        })),
        acknowledgementText: values.acknowledgementText,
        documentDescription: values.documentDescription || '',
        documentTitle: values.documentTitle,
        templateName: values.templateName,
        documentInstructions: values.documentInstructions
    };
};

export enum ClientSection {
    To = 'to',
    From = 'from'
}

export enum ClientType {
    Agent = 'agent',
    Landlord = 'landlord',
    Tenant = 'tenant',
    Vendor = 'vendor',
    Custom = 'custom',
    TradingName = 'trading name',
    Other = 'other'
}

export type QuestionUiModel = {
    number: number;
    title?: string;
    shouldShowDetailsOption?: boolean;
    shouldShowAnswerSection?: boolean;
    showPlaceholderText: boolean;
    hasError?: boolean;
};

type ClientFieldUiModel = {
    mappingName?: string;
    label: string;
    displayLabel: string;
    type: FieldType;
    isRequired?: boolean;
    isRemovable?: boolean;
    isRemoved?: boolean;
    isOther?: boolean;
};

type RemovedClientFieldUiModel = {
    label: string;
    fieldIndex: number;
};

export type ClientUiModel = {
    showContactSearch?: boolean;
    showIsPrimary?: boolean;
    showIsCorporation?: boolean;
    showIncludeLandlordAgentDetails?: boolean;
    typeDisplayName: string;
    disableTypeSelect?: boolean;
    fields: ClientFieldUiModel[];
    // a reference to the fields that have been removed, so that they can be restored
    removedFields: RemovedClientFieldUiModel[];
    clientNumber: number;
    tagColor: TagColor;
    displayName?: string;
    showClientTypeSelector: boolean;
    clientTypeOptions: { value: ClientType; label: string }[];
    sectionDisplayName: string;
    isDeletable: boolean;
    hasError?: boolean;
    disableClientDetails?: boolean;
};

export type ClientSectionUiModel = {
    type: ClientType;
    clients: ClientUiModel[];
};

export type BuildADocUiModel = {
    to: ClientSectionUiModel;
    from: ClientSectionUiModel;
    questions: QuestionUiModel[];
    questionSectionDisplayNameLowercase: string;
    showInstructionsField?: boolean;
    showInstructions?: boolean;
    isReadOnly?: boolean;
};

export const getClientConfig = (clientType: ClientType, isCorporation?: boolean): ClientFieldConfig => {
    switch (clientType) {
        case ClientType.Agent:
            return agentFieldsConfig;
        case ClientType.Landlord:
            return isCorporation ? corporationLandlordFieldsConfig : landlordFieldsConfig;
        case ClientType.Tenant:
        case ClientType.Vendor:
        case ClientType.Other:
            return isCorporation ? corporationEntityFieldsConfig : entityFieldsConfig;
        case ClientType.Custom:
            return customEntityFieldsConfig;
        case ClientType.TradingName:
            return tradingNameFieldsConfig;
    }
};

const getClientTypeDisplayNameLowerCase = (clientType: ClientType, clientSection: ClientSection) => {
    switch (clientType) {
        case ClientType.Agent:
            return 'agent';
        case ClientType.Landlord:
            return 'landlord';
        case ClientType.Tenant:
            return 'tenant';
        case ClientType.Vendor:
            return 'vendor';
        case ClientType.TradingName:
            return 'office';
        case ClientType.Custom:
        case ClientType.Other:
            return clientSection === ClientSection.To ? 'recipient' : 'sender';
    }
};

const getSectionDisplayNameLowerCase = (clientSection: ClientSection) => {
    switch (clientSection) {
        case ClientSection.From:
            return 'sender';
        case ClientSection.To:
            return 'recipient';
    }
};

export const generateNewClient = (
    clientType: ClientType,
    clientSection: ClientSection,
    loggedInUser?: User,
    useAgencyDetails = false,
    isCorporation = false
): FormModelClient => {
    const clientConfig = getClientConfig(clientType, isCorporation);
    const clientData = clientConfig.fields.map(field => {
        let fieldValue = '';

        if (useAgencyDetails && field.mappingName && loggedInUser?.agency) {
            fieldValue = loggedInUser.agency.details[field.mappingName];
        }

        return {
            label: field.label,
            value: fieldValue,
            meta: {
                order: field.order,
                type: field.type
            }
        };
    });

    if (useAgencyDetails && loggedInUser?.agency) {
        loggedInUser.agency.details.otherDetails?.forEach(detail => {
            clientData.push({
                label: detail.label,
                value: detail.value,
                meta: {
                    order: clientData.length,
                    type: FieldType.Text
                }
            });
        });
    }

    return {
        data: clientData,
        source: !useAgencyDetails ? CONTACT_TYPE_AUTO_SAVE : undefined
    };
};

const getClientDisplayName = (client: FormModelClient, clientType: ClientType) => {
    if (clientType === ClientType.Agent) {
        return client.data.find(data => data.label === 'Agency Name')?.value;
    } else if (clientType === ClientType.TradingName) {
        return client.data.find(data => data.label === 'Trading Name')?.value;
    } else if (client.isCorporation) {
        return client.data.find(data => data.label === 'Corporation Name')?.value;
    } else {
        const firstName = client.data.find(data => data.label === 'First Name')?.value;
        const middleName = client.data.find(data => data.label === 'Middle Name')?.value;
        const lastName = client.data.find(data => data.label === 'Last Name')?.value;

        return [firstName, middleName, lastName].filter(name => !!name).join(' ');
    }
};

const getFieldDisplayLabel = (fieldLabel: string, clientSection: ClientSection) => {
    switch (fieldLabel) {
        case 'Type':
            return `${capitalize(getSectionDisplayNameLowerCase(clientSection))} Title`;
        default:
            return fieldLabel;
    }
};

const projectClientSectionUiModel = (
    type: ClientType,
    clients: FormModelClient[],
    section: ClientSection,
    isBusinessUser: boolean,
    sectionErrors?: ValidationErrors,
    deliveryType?: DeliveryType,
    loggedInUser?: User,
    isGlobalTemplateMode?: boolean
): ClientSectionUiModel => {
    const clientTypeOptions = getClientTypeOptions(section, isBusinessUser, loggedInUser?.agency?.details?.agencyName);
    // Show type selector for all clients except for FLK B recipients
    const showClientTypeSelector = !(isBusinessUser && section === ClientSection.To);

    return {
        type,
        clients: clients.map((client, index) => {
            const clientConfig = getClientConfig(type, client.isCorporation);

            const { fields, removedFields } = client.data.reduce(
                (acc, data, index) => {
                    // Had to trim the label because Agency Address has a space at the end
                    const field = clientConfig.fields.find(field => field.label === data.label?.trim());

                    acc.fields.push({
                        ...field,
                        isRequired:
                            field?.isRequired ||
                            (deliveryType === DeliveryType.SMS && data.meta.type === FieldType.Phone) ||
                            (deliveryType === DeliveryType.Email && data.meta.type === FieldType.Email),
                        label: data.label,
                        isOther: data.isOther,
                        isRemoved: data.isRemoved,
                        displayLabel: getFieldDisplayLabel(data.label, section),
                        isRemovable:
                            !field?.isRequired &&
                            data.meta.type !== FieldType.Email &&
                            data.meta.type !== FieldType.Phone,
                        type: field?.type || FieldType.Text
                    });

                    if (data.isRemoved) {
                        acc.removedFields.push({
                            label: data.label,
                            fieldIndex: index
                        });
                    }

                    return acc;
                },
                { fields: [] as ClientFieldUiModel[], removedFields: [] as RemovedClientFieldUiModel[] }
            );

            return {
                ...clientConfig,
                clientNumber: index + 1,
                tagColor: getClientTagColor(section, index),
                fields,
                removedFields,
                showContactSearch: type !== ClientType.Agent,
                disableTypeSelect: index > 0,
                typeDisplayName: getClientTypeDisplayNameLowerCase(type, section),
                showIsPrimary: section === ClientSection.To,
                displayName: getClientDisplayName(client, type),
                clientTypeOptions,
                showClientTypeSelector,
                isDeletable: clients.length > 1,
                sectionDisplayName: getSectionDisplayNameLowerCase(section),
                hasError: !!sectionErrors?.clients?.[index],
                disableClientDetails: isGlobalTemplateMode
            };
        })
    };
};

export const projectUiModel = (
    values: FormModel,
    errors: ValidationErrors,
    isBusinessUser: boolean,
    loggedInUser: User,
    isTemplateMode: boolean,
    isReadOnly: boolean,
    isGlobalTemplateMode: boolean
): BuildADocUiModel => {
    return {
        from: projectClientSectionUiModel(
            values.from.type,
            values.from.clients,
            ClientSection.From,
            isBusinessUser,
            errors?.from,
            values.deliveryType,
            loggedInUser,
            isGlobalTemplateMode
        ),
        to: projectClientSectionUiModel(
            values.to.type,
            values.to.clients,
            ClientSection.To,
            isBusinessUser,
            errors?.to,
            values.deliveryType
        ),
        questions: values.questions?.map((question, index) => ({
            number: index + 1,
            title: question.title,
            shouldShowAnswerSection: question.requireClientAction,
            shouldShowDetailsOption: question.answerOptionsSelected?.includes('yes'),
            showPlaceholderText: index === 0,
            hasError: !!errors?.questions?.[index]
        })),
        questionSectionDisplayNameLowercase: isBusinessUser ? 'section' : 'clause',
        showInstructionsField: isTemplateMode,
        showInstructions: !!values.documentInstructions && !isTemplateMode,
        isReadOnly: isReadOnly
    };
};
