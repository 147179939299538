import cx from 'classnames';
import React from 'react';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import Icon, { Icons } from '@app/common/components/Icon';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import { Tag, TagColor, TagSize } from '@app/common/components/tag/Tag';
import { UploadADoc, Client as UploadADocClient } from '@app/types/UploadADoc';
import { getTagColour } from './utils';
import Button from '@app/common/components/Button.js';
import { useCopySigningLink } from '@app/hooks/useSigningLink';
import { COPY_DOCUMENT_SIGNING_LINKS } from '@app/constants/featureFlags';

import styles from './RecipientSummary.module.scss';

type Client = Partial<
    Pick<
        UploadADocClient,
        'name' | 'email' | 'phone' | 'source' | 'signingOrder' | 'id' | 'isSigned' | 'acknowledgedDate'
    >
>;

type RecipientSummaryProps = {
    client: Client;
    index: number;
    hasError?: boolean;
    showCopyLink?: boolean;
    document?: Pick<UploadADoc, 'id' | 'sentForSigning'>;
};

export const RecipientSummaryContent: React.FC<RecipientSummaryProps> = ({
    client,
    index,
    hasError,
    showCopyLink,
    document
}) => {
    const isCopySigningLinksEnabled = useFeatureFlag(COPY_DOCUMENT_SIGNING_LINKS);

    const { copySigningLink, isSigningLinkLoading } = useCopySigningLink(
        client.id,
        document?.id,
        document?.sentForSigning,
        { disabled: !isCopySigningLinksEnabled || !showCopyLink || !!client.isSigned || !!client.acknowledgedDate }
    );

    const { name, email, phone } = client;
    const itemNumber = index + 1;

    return (
        <div
            className={cx(styles.recipientSummary, {
                [styles.error]: hasError,
                [styles.withEmail]: !!email
            })}
            data-has-error={hasError}
        >
            <Tag
                color={getTagColour(index)}
                className={cx(styles.number, {
                    [styles.singleDigit]: itemNumber < 10
                })}
                text={itemNumber.toString()}
                size={TagSize.X_Small}
            />
            {name && <div className={styles.name}>{name}</div>}
            {email && <div className={styles.email}>{email}</div>}
            {phone && <div className={styles.phone}>{phone}</div>}
            {hasError && (
                <Tag
                    className={styles.errorTag}
                    text="Needs Attention"
                    color={TagColor.Error}
                    startIcon={<Icon icon={Icons.ERROR} />}
                    size={TagSize.Small}
                />
            )}
            {showCopyLink && isCopySigningLinksEnabled && (
                <Button
                    type="button"
                    className={styles.copyLinkButton}
                    secondary
                    onClick={copySigningLink}
                    loading={isSigningLinkLoading}
                    disabled={client.isSigned || !!client.acknowledgedDate}
                    tooltip={client.isSigned || client.acknowledgedDate ? 'Recipient has already signed.' : undefined}
                >
                    Copy signing link
                </Button>
            )}
        </div>
    );
};

export const RecipientSummary: React.FC<RecipientSummaryProps> = props => {
    return (
        <Card key={props.index} style={CardStyles.SQUARE} shadow={CardShadow.SMALL}>
            <RecipientSummaryContent {...props} />
        </Card>
    );
};
