import * as React from 'react';
import { Form } from 'react-final-form';
import { has, isEmpty, isString } from 'lodash';
import { updateLandlord, getLandlordAndProgressSteps } from '../../actions/lease';
import EditLandlordPersonGroup from '../lease/mainScreen/common/EditLandlordPersonGroup';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../../sass/tenant.scss';
import { getAgreementInfo } from '../../selectors/dashboard/agreementInfo';
import { getIsSubmittingLease, getValidationErrors } from '../../selectors/lease';
import { getPmLandlord } from '../../selectors/lease/pmLease';

const EditLandlord = () => {
    const dispatch = useDispatch();
    const pmLandlord = useSelector(getPmLandlord);
    const validationErrors = useSelector(getValidationErrors);
    const agreementInfo = useSelector(getAgreementInfo);
    const isSubmittingLease = useSelector(getIsSubmittingLease);

    const isTenantConfirmedError = () => {
        if (
            validationErrors &&
            validationErrors.pmLandlord &&
            validationErrors.pmLandlord.persons &&
            validationErrors.pmLandlord.persons[0] &&
            validationErrors.pmLandlord.persons[0].isEditable
        ) {
            return true;
        }
        return false;
    };
    const isEditable = () => {
        let editable = false;
        pmLandlord.persons.forEach(user => {
            if (
                isEmpty(user.steps) ||
                !user.steps.steps ||
                !user.steps.steps[1] ||
                user.steps.steps[1].status !== 'confirmed'
            ) {
                editable = true;
            }
        });
        return editable;
    };

    useEffect(() => {
        dispatch(getLandlordAndProgressSteps(agreementInfo.id));
    }, []);

    useEffect(() => {
        if (isTenantConfirmedError()) {
            dispatch(getLandlordAndProgressSteps(agreementInfo.id));
        }
    }, [validationErrors]);

    const submitValues = values => {
        values.persons.forEach(landlord => {
            if (typeof landlord.email === 'undefined') {
                landlord.email = '';
            }
        });
        dispatch(updateLandlord(agreementInfo.id, values, agreementInfo.deadline));
    };
    return (
        <div className="tenant">
            <Form
                onSubmit={submitValues}
                initialValues={pmLandlord}
                validate={() => {
                    return validationErrors && validationErrors.pmLandlord ? validationErrors.pmLandlord : {};
                }}
            >
                {({ handleSubmit, errors, values }) => {
                    return (
                        <form id="update-tenant-detail-form" onSubmit={handleSubmit} noValidate>
                            <div className="tenants">
                                {validationErrors &&
                                    validationErrors.pmLandlord &&
                                    validationErrors.pmLandlord.persons &&
                                    validationErrors.pmLandlord.persons[0] &&
                                    validationErrors.pmLandlord.persons[0].isEditable && (
                                        <p className="FormInput">
                                            <span className="FormError">
                                                {validationErrors.pmLandlord.persons[0].isEditable}
                                            </span>
                                        </p>
                                    )}
                                {pmLandlord.persons.map((landlord, index) => (
                                    <EditLandlordPersonGroup key={index} index={index} landlord={landlord} />
                                ))}
                            </div>
                            {has(errors, 'persons') && isString(errors.persons) && (
                                <div className="has-error custom-error-message">{errors.persons}</div>
                            )}
                            <div className="btn-edit-signatory">
                                <button
                                    type="button"
                                    disabled={isSubmittingLease || !isEditable()}
                                    onClick={() => submitValues(values)}
                                >
                                    {isSubmittingLease && <span className="savestatus-spinner-white" />}
                                    Save changes
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default EditLandlord;
