import axios from 'axios';
import appHistory from '../AppHistory';
import * as Lease from '../reducers/lease';
import * as Signatory from '../reducers/sales/signatory';
import * as Term from '../reducers/lease/term';
import * as Contact from '../reducers/lease/contact';
import * as Additional from '../reducers/lease/additional';
import * as Documents from '../reducers/lease/documents';
import * as Connections from '../reducers/lease/connections';
import * as LandlordsSolicitor from '../reducers/sales/landlordsSolicitor';
import * as PriceAndFee from '../reducers/sales/priceAndFee';
import * as AgentAuthority from '../reducers/lease/agentAuthority';
import * as Fees from '../reducers/lease/fees';
import * as MaterialFacts from '../reducers/lease/materialFacts';
import * as ExpensesAndCharges from '../reducers/lease/expensesAndCharges';
import * as OtherServices from '../reducers/lease/otherServices';
import * as Advertising from '../reducers/lease/advertising';
import * as LeaseRentAndTerm from '../reducers/lease/leaseRentAndTerm';

import * as SalesLandlord from '../reducers/sales/landlord';
import * as SalesInclusions from '../reducers/sales/inclusions';
import * as SalesExpensesAndCharges from '../reducers/sales/salesExpensesAndCharges';
import * as AdditionalSalesTerms from '../reducers/sales/additionalSalesTerms';

import { formatDocumentsForApi } from '../utils/formUtils';

import { UPDATE_SALES_TERM_FORM } from '../reducers/sales/term';
/**
 * Open lease agreement form.
 * @param {string} leaseType - type of lease agreement
 * @returns {{type, leaseType: *}}
 */
export function openLeaseAgreementForm(leaseType, openedFromWelcome) {
    return {
        type: Lease.OPEN_LEASE_AGREEMENT_FORM,
        leaseType,
        openedFromWelcome
    };
}

/**
 * Close lease agreement form
 * @returns {{type: *}}
 */
export function closeLeaseAgreementForm() {
    return {
        type: Lease.CLOSE_LEASE_AGREEMENT_FORM
    };
}

export function openCloneResTenForm(leaseId, leaseType) {
    return {
        type: Lease.OPEN_CLONE_RES_TEN_FORM,
        leaseType,
        leaseId
    };
}

export function closeCloneResTenForm(leaseType) {
    return {
        type: Lease.CLOSE_CLONE_RES_TEN_FORM,
        leaseType
    };
}

export function createLease(data) {
    return dispatch => {
        dispatch({
            type: Lease.CREATE_LEASE_REQUEST
        });

        return axios
            .post(`/api/agency/lease`, data)
            .then(result => {
                dispatch({
                    type: Lease.CREATE_LEASE_SUCCESS,
                    result
                });
            })
            .catch(error => {
                dispatch({
                    type: Lease.CREATE_LEASE_FAILURE,
                    error
                });
            });
    };
}

/**
 * Used when creating a Lease and Integration slider is ON
 * @param {string} propertyId
 * @param {object} data
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function createLeaseViaIntegration(propertyId, data) {
    return {
        types: [Lease.CREATE_LEASE_REQUEST, Lease.CREATE_LEASE_SUCCESS, Lease.CREATE_LEASE_FAILURE],
        promise: axios.post(`/api/integrations/create-lease/${propertyId}`, data)
    };
}

/**
 * Get Integration Lease Data (ex. Property Tree Lease details) using our lease-agreement
 * @param {string} leaseId
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getIntegrationLeaseDetails(leaseId) {
    return {
        types: [
            Lease.LEASE_PULL_INTEGRATION_DATA.REQUEST,
            Lease.LEASE_PULL_INTEGRATION_DATA.SUCCESS,
            Lease.LEASE_PULL_INTEGRATION_DATA.FAIL
        ],
        promise: axios.get(`/api/integrations/lease-details/${leaseId}`)
    };
}

/**
 * Pull condition-report and save to lease-agreement
 * @param {string} leaseId
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function pullLeaseConditionReport(leaseId) {
    return {
        types: [
            Lease.PULL_LEASE_CONDITION_REPORT.REQUEST,
            Lease.PULL_LEASE_CONDITION_REPORT.SUCCESS,
            Lease.PULL_LEASE_CONDITION_REPORT.FAIL
        ],
        promise: axios.post(`/api/agency/lease/${leaseId}/pull-condition-report`)
    };
}

/**
 * Delete condition-report from lease-agreement
 * @param {string} leaseId
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function removeLeaseConditionReport(leaseId) {
    return {
        types: [
            Lease.REMOVE_LEASE_CONDITION_REPORT.REQUEST,
            Lease.REMOVE_LEASE_CONDITION_REPORT.SUCCESS,
            Lease.REMOVE_LEASE_CONDITION_REPORT.FAIL
        ],
        promise: axios.delete(`/api/agency/lease/${leaseId}/condition-report`)
    };
}

export function enableIntegrationLeaseDataSync(data) {
    return {
        type: Lease.ENABLE_LEASE_DATA_SYNC,
        payload: data
    };
}

export function updateIntegrationLeaseDataSync(data) {
    return {
        type: Lease.UPDATE_LEASE_DATA_SYNC,
        payload: data
    };
}

export function cleanIntegrationLeaseDataSync() {
    return {
        type: Lease.CLEAN_LEASE_DATA_SYNC
    };
}

export function cloneResTenAgreement(cloneLeaseId, data) {
    return dispatch => {
        dispatch({
            type: Lease.CLONE_AGREEMENT_EXTRA_REQUEST
        });

        return axios
            .post(`/api/agency/lease/${cloneLeaseId}/clone-extra`, data)
            .then(result => {
                appHistory.push(`/dashboard/agreement/${result.data.lease.id}`);
                dispatch({
                    type: Lease.CLONE_AGREEMENT_EXTRA_SUCCESS,
                    result
                });
            })
            .catch(error => {
                dispatch({
                    type: Lease.CLONE_AGREEMENT_EXTRA_FAILURE,
                    error
                });
            });
    };
}

/**
 * Rest store and return to the dashboard.
 *
 * @return {{type}}
 */
export function closeLease() {
    appHistory.push('/dashboard');
    return { type: Lease.CLOSE_LEASE_MODAL };
}

export function handleFormChange(type, data) {
    let actionName;
    switch (type) {
        case 'signatory': {
            actionName = Signatory.UPDATE_SIGNATORY_FORM;
            break;
        }
        case 'salesLandlord': {
            actionName = SalesLandlord.UPDATE_SALES_LANDLORD_FORM;
            break;
        }
        case 'term': {
            actionName = Term.UPDATE_TERM_FORM;
            break;
        }
        case 'salesInclusions': {
            actionName = SalesInclusions.UPDATE_SALES_INCLUSIONS_FORM;
            break;
        }
        case 'contacts': {
            actionName = Contact.UPDATE_CONTACT_FORM;
            break;
        }
        case 'additional': {
            actionName = Additional.UPDATE_ADDITIONAL_FORM;
            break;
        }
        case 'documents': {
            actionName = Documents.UPDATE_DOCUMENTS_FORM;
            break;
        }
        case 'connections': {
            actionName = Connections.UPDATE_CONNECTIONS_FORM;
            break;
        }
        case 'landlordsSolicitor': {
            actionName = LandlordsSolicitor.UPDATE_SALES_LANDLORD_SOLICITOR_FORM;
            break;
        }
        case 'priceAndFee': {
            actionName = PriceAndFee.UPDATE_PRICE_AND_FEE_FORM;
            break;
        }

        case 'salesExpensesAndCharges': {
            actionName = SalesExpensesAndCharges.UPDATE_SALES_EXPENSES_AND_CHARGES_FORM;
            break;
        }
        case 'materialFacts': {
            actionName = MaterialFacts.UPDATE_MATERIAL_FACTS_FORM;
            break;
        }
        case 'agentAuthority': {
            actionName = AgentAuthority.UPDATE_AGENT_AUTHORITY_FORM;
            break;
        }
        case 'fees': {
            actionName = Fees.UPDATE_FEES_FORM;
            break;
        }
        case 'expensesAndCharges': {
            actionName = ExpensesAndCharges.UPDATE_EXPENSES_AND_CHARGES_FORM;
            break;
        }
        case 'otherServices': {
            actionName = OtherServices.UPDATE_OTHER_SERVICES_FORM;
            break;
        }
        case 'advertising': {
            actionName = Advertising.UPDATE_ADVERTISING_FORM;
            break;
        }
        case 'leaseRentAndTerm': {
            actionName = LeaseRentAndTerm.UPDATE_LEASE_RENT_AND_TERM_FORM;
            break;
        }
        case 'address': {
            actionName = Lease.UPDATE_LEASE_AGREEMENT_ADDRESS;
            break;
        }
        case 'salesTerm': {
            actionName = UPDATE_SALES_TERM_FORM;
            break;
        }
        case 'additionalSalesTerms': {
            actionName = AdditionalSalesTerms.UPDATE_ADDITIONAL_SALES_TERMS_FORM;
            break;
        }
    }
    return {
        type: actionName,
        data: data
    };
}
export function updateSubmitTypeSuccess(result, type) {
    return dispatch => {
        return new Promise(resolve => {
            dispatch({
                type,
                result
            });
            resolve();
        });
    };
}
export function updateTermSuccess(result) {
    return dispatch => {
        return new Promise(resolve => {
            dispatch({
                type: Lease.LEASE_TERM_SUCCESS,
                result
            });

            resolve();
        });
    };
}
export function setNavigationState(goToStep) {
    return {
        type: Lease.SET_NAVIGATION_STATE,
        payload: { goToStep }
    };
}

export function formSubmitFail(errors, leaseType, location) {
    return {
        type: Lease.SUBMIT_FORM_FAIL,
        payload: { errors, leaseType, location }
    };
}

export function setDirtyStep(step) {
    return {
        type: Lease.SET_DIRTY_STEP,
        payload: { step }
    };
}

export function resetDirtyStep() {
    return {
        type: Lease.RESET_DIRTY_STEP
    };
}

export function setLandlord(leaseId, data) {
    return {
        types: [Lease.LEASE_LANDLORD_REQUEST, Lease.LEASE_LANDLORD_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/landlord`, data)
    };
}

/** @deprecated - This seems to be unused anywhere */
export function setSignatory(leaseId, data) {
    return {
        types: [Lease.LEASE_SIGNATORY_REQUEST, Lease.LEASE_SIGNATORY_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/signatory`, data)
    };
}

/** @deprecated - This seems to be unused anywhere */
export function setTenant(leaseId, data) {
    return {
        types: [Lease.LEASE_TENANT_REQUEST, Lease.LEASE_TENANT_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/tenant`, data)
    };
}

export function updateTenant(leaseId, data, resendDeadline) {
    return {
        types: [Lease.UPDATE_TENANT_REQUEST, Lease.UPDATE_TENANT_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/update-tenant`, data),
        payload: {
            data: {
                deadline: resendDeadline
            }
        }
    };
}

/** @deprecated - This seems to be unused anywhere */
export function setTenantsAgent(leaseId, data) {
    return {
        types: [Lease.LEASE_TENANTS_AGENT_REQUEST, Lease.LEASE_TENANTS_AGENT_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/tenants-agent`, data)
    };
}

export function setTerm(leaseId, data) {
    return {
        types: [Lease.LEASE_TERM_REQUEST, Lease.LEASE_TERM_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/term`, data)
    };
}

export function setRent(leaseId, data) {
    return {
        types: [Lease.LEASE_RENT_REQUEST, Lease.LEASE_RENT_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/rent`, data)
    };
}

export function setPaymentMethod(leaseId, data) {
    data.isDefaultSettings = false;
    return {
        types: [Lease.LEASE_PAYMENT_REQUEST, Lease.LEASE_PAYMENT_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/payment-details`, data)
    };
}

export function setInclusions(leaseId, data) {
    return {
        types: [Lease.LEASE_INCLUSIONS_REQUEST, Lease.LEASE_INCLUSIONS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/inclusions`, data)
    };
}

export function setExclusions(leaseId, data) {
    return {
        types: [Lease.LEASE_EXCLUSIONS_REQUEST, Lease.LEASE_EXCLUSIONS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/exclusions`, data)
    };
}
export function setMaxOccupants(leaseId, data) {
    return {
        types: [Lease.LEASE_OCCUPANTS_REQUEST, Lease.LEASE_OCCUPANTS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/occupants`, data)
    };
}

export function removeOccupantsSuccess(result) {
    return {
        type: Lease.SEND_REMOVE_OCCUPANT_SUCCESS,
        result
    };
}

export function setPriceAndFee(leaseId, data) {
    return {
        types: [Lease.LEASE_PRICE_AND_FEE_REQUEST, Lease.LEASE_PRICE_AND_FEE_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/price-and-fee`, data)
    };
}

export function setMarketingAndAdvertising(leaseId, data) {
    return {
        types: [
            Lease.LEASE_MARKETING_AND_ADVERTISING_REQUEST,
            Lease.LEASE_MARKETING_AND_ADVERTISING_SUCCESS,
            Lease.LEASE_FORM_FAIL
        ],
        promise: axios.post(`/api/agency/lease/${leaseId}/marketing-and-advertising`, data)
    };
}

export function setInspectionDays(leaseId, data) {
    return {
        types: [Lease.LEASE_INSPECTION_DAYS_REQUEST, Lease.LEASE_INSPECTION_DAYS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/inspection-days`, data)
    };
}

export function setAgreementTerm(leaseId, data) {
    return {
        types: [Lease.LEASE_AGREEMENT_TERM_REQUEST, Lease.LEASE_AGREEMENT_TERM_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/agreement-term`, data)
    };
}

export function setAgentAuthority(leaseId, data) {
    return {
        types: [Lease.LEASE_AGENT_AUTHORITY_REQUEST, Lease.LEASE_AGENT_AUTHORITY_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/agent-authority`, data)
    };
}

export function setFees(leaseId, data) {
    return {
        types: [Lease.LEASE_FEES_REQUEST, Lease.LEASE_FEES_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/fees`, data)
    };
}
export function setMaterialFacts(leaseId, data) {
    return {
        types: [Lease.LEASE_MATERIAL_FACTS_REQUEST, Lease.LEASE_MATERIAL_FACTS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/material-facts`, data)
    };
}

export function setExpensesAndCharges(leaseId, data) {
    return {
        types: [
            Lease.LEASE_EXPENSES_AND_CHARGES_REQUEST,
            Lease.LEASE_EXPENSES_AND_CHARGES_SUCCESS,
            Lease.LEASE_FORM_FAIL
        ],
        promise: axios.post(`/api/agency/lease/${leaseId}/expenses-and-charges`, data)
    };
}

export function setOtherServices(leaseId, data) {
    return {
        types: [Lease.LEASE_OTHER_SERVICES_REQUEST, Lease.LEASE_OTHER_SERVICES_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/other-services`, data)
    };
}

export function setAdvertising(leaseId, data) {
    return {
        types: [Lease.LEASE_ADVERTISING_REQUEST, Lease.LEASE_ADVERTISING_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/advertising`, data)
    };
}

export function setLeaseRentAndTerm(leaseId, data) {
    return {
        types: [Lease.LEASE_RENT_AND_TERM_REQUEST, Lease.LEASE_RENT_AND_TERM_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/lease-rent-and-term`, data)
    };
}

export function setDisclosureOfRebates(leaseId, data) {
    return {
        types: [Lease.LEASE_DISCLOSURE_REBATE_REQUEST, Lease.LEASE_DISCLOSURE_REBATE_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/rebate-requests`, data)
    };
}

//emergency contact
export function addContactPerson(contact) {
    return {
        type: Contact.CONTACT_ADD_PERSON,
        contact
    };
}

export function removeContactPerson(index) {
    return {
        type: Contact.CONTACT_REMOVE_PERSON,
        index
    };
}

export function setEmergencyContacts(leaseId, data) {
    let requestData;
    if (data) {
        if (data.isLeasePmAndQld) {
            requestData = data;
        } else if (!data.contacts.length) {
            requestData = null;
        } else {
            requestData = data.contacts;
        }
    }
    return {
        types: [Lease.LEASE_CONTACTS_REQUEST, Lease.LEASE_CONTACTS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/emergency-contacts`, requestData)
    };
}

export function setAdditionalTerms(leaseId, data) {
    return {
        types: [Lease.LEASE_ADDITIONAL_REQUEST, Lease.LEASE_ADDITIONAL_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/additional-terms`, data)
    };
}

export function setSpecialConditions(leaseId, data) {
    return {
        types: [Lease.LEASE_CONDITIONS_REQUEST, Lease.LEASE_CONDITIONS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/special-conditions`, data)
    };
}

export function setDocuments(leaseId, data, additionalData, bypassFormValidation = false) {
    let documents = formatDocumentsForApi(data.documents);
    return dispatch => {
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                dispatch({
                    type: Lease.UPDATE_DOCUMENT_UPLOAD_PROGRESS,
                    progress: Math.floor((loaded * 100) / total)
                });
            }
        };

        dispatch({
            type: Lease.LEASE_DOCUMENTS_REQUEST,
            additionalData
        });

        return new Promise((resolve, reject) => {
            return axios
                .post(
                    `/api/agency/lease/${leaseId}/documents?bypassFormValidation=${bypassFormValidation}`,
                    documents,
                    options
                )
                .then(result => {
                    dispatch({
                        type: Lease.LEASE_DOCUMENTS_SUCCESS,
                        result,
                        additionalData,
                        bypassFormValidation
                    });
                    resolve(result);
                })
                .catch(error => {
                    dispatch({
                        type: Lease.LEASE_FORM_FAIL,
                        error,
                        additionalData
                    });
                    reject(error);
                });
        });
    };
}

export function setConnections(leaseId, data) {
    return {
        types: [Lease.LEASE_CONNECTIONS_REQUEST, Lease.LEASE_CONNECTIONS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/connections`, data)
    };
}

export function getInfoLease(leaseId) {
    return dispatch => {
        dispatch({
            type: Lease.GET_INFO_LEASE_REQUEST
        });

        return axios
            .get(`/api/agency/lease/${leaseId}`)
            .then(result => {
                dispatch({
                    type: Lease.GET_INFO_LEASE_SUCCESS,
                    result
                });
                return result;
            })
            .catch(error => {
                dispatch({
                    type: Lease.GET_INFO_LEASE_FAIL,
                    error
                });
            });
    };
}

export function getTenantAndProgressSteps(leaseId) {
    return {
        types: [Lease.LEASE_TENANT_DETAILS_REQUEST, Lease.LEASE_TENANT_DETAILS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.get(`api/agency/lease/${leaseId}/tenant-and-progress`)
    };
}
export function getSignorLinks(leaseId) {
    return {
        promise: axios.get(`api/agency/lease/${leaseId}/signorLinks`)
    };
}
export function goToStep(step) {
    return {
        type: Lease.CHANGE_STEP_SIGNAL,
        data: step
    };
}

export function goBack() {
    return {
        type: Lease.GO_BACK_SIGNAL
    };
}

export function setSigning(leaseId, data) {
    return {
        types: [Lease.POST_SIGNING_REQUEST, Lease.POST_SIGNING_SUCCESS, Lease.POST_SIGNING_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/signing`, data),
        payload: { data }
    };
}

export function resendForSigning(leaseId, data) {
    return {
        types: [Lease.RESEND_POST_SIGNING_REQUEST, Lease.RESEND_POST_SIGNING_SUCCESS, Lease.RESEND_POST_SIGNING_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/resend-for-signing`, data)
    };
}

/**
 * Opening a lease agreement form editing at the required step.
 * @param {object} agreementInfo - agreement information
 * @param {number} step - step number
 * @returns {{type, payload: {agreementInfo: *, step: *}}}
 */
export function editLeaseAgreement(user, agreementInfo, step) {
    return {
        type: Lease.EDIT_LEASE_AGREEMENT_FORM,
        payload: { user, agreementInfo, step }
    };
}

/**
 * Opening a lease agreement form editing at the required step.
 * @param {object} agreementInfo - agreement information
 * @returns {{type, payload: {agreementInfo: *, step: *}}}
 */
export function initialiseStateAgreement(agreementInfo) {
    return {
        type: Lease[`INITIALIZE_${agreementInfo.location.toUpperCase()}_LEASE_AGREEMENT_FORM`],
        payload: { agreementInfo }
    };
}

export function setFinishEditingMode() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: Lease.FINISH_EDITING_MODE
            });

            resolve();
        });
    };
}

export function setSideMenuStep(step) {
    return dispatch => {
        return new Promise(resolve => {
            dispatch({
                type: Lease.SET_SIDE_MENU_STEP,
                goToStep: step
            });

            resolve();
        });
    };
}

export function setMoveToNextStepEditingMode() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: Lease.MOVE_TO_NEXT_STEP_EDITING_MODE
            });

            resolve();
        });
    };
}

/**
 * Completion of editing and saving of changes under the lease agreement.
 *
 * @param leaseId - id of lease agreement
 * @param data - changed lease agreement data
 * @returns {function()}
 */
export function updateAgreement(leaseId, data) {
    return dispatch => {
        let documents = formatDocumentsForApi(data.documents);
        delete data.documents;
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                dispatch({
                    type: Lease.UPDATE_DOCUMENT_UPLOAD_PROGRESS,
                    progress: Math.floor((loaded * 100) / total)
                });
            }
        };

        dispatch({
            type: Lease.LEASE_DOCUMENTS_REQUEST
        });
        dispatch({
            type: Lease.FINISH_EDITING_LEASE_AGREEMENT_REQUEST
        });

        return axios
            .post(`/api/agency/lease/${leaseId}/documents`, documents, options)
            .then(result => {
                dispatch({
                    type: Lease.LEASE_DOCUMENTS_SUCCESS,
                    result,
                    editMode: true
                });
                return axios.put(`/api/agency/lease/${leaseId}`, data);
            })
            .then(leaseResult => {
                dispatch({
                    type: Lease.FINISH_EDITING_LEASE_AGREEMENT_SUCCESS,
                    result: leaseResult
                });
            })
            .catch(error => {
                dispatch({
                    type: Lease.LEASE_FORM_FAIL,
                    error,
                    data
                });
                throw error;
            });
    };
}

/**
 * Update integration of agreement
 * @param {object} data
 * @returns {{type: *, integration: *}}
 */
export function updateIntegration(data) {
    return {
        type: Lease.UPDATE_INTEGRATION_SUCCESS,
        data
    };
}
/**
 * Editing lease agreement address.
 * @param {string} address
 * @returns {{type: *, address: *}}
 */
export function editAddress(address) {
    return {
        type: Lease.EDIT_LEASE_AGREEMENT_ADDRESS,
        address: address
    };
}

export function editAgent(agent) {
    return {
        type: Lease.EDIT_LEASE_AGREEMENT_AGENT,
        agent: agent
    };
}
/**
 *
 * @param {string} leaseId - id of lease agreement
 * @param {number} step - step number of the lease agreement for which the change request was made
 * @returns {{type: *, payload: {leaseId: *, step: *}}}
 */
export function setActiveRequest(leaseId, step) {
    return {
        type: Lease.SET_ACTIVE_REQUEST,
        payload: { leaseId, step }
    };
}

/**
 * Un resolve request to change a part of lease agreement.
 * @param {string} requestId - ID of request to change
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function unresolveRequest(requestId) {
    return {
        types: [
            Lease.UNRESOLVE_REQUEST_TO_CHANGE_REQUEST,
            Lease.CHANGE_REQUEST_TO_CHANGE_SUCCESS,
            Lease.UNRESOLVE_REQUEST_TO_CHANGE_FAIL
        ],
        promise: axios.put(`/api/agency/request-to-change/${requestId}/unresolve`)
    };
}

/**
 * Get addresses on query.
 * @param {string} query
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getAddresses(query) {
    return {
        types: [Lease.GET_ADDRESSES_REQUEST, Lease.GET_ADDRESSES_SUCCESS, Lease.GET_ADDRESSES_FAIL],
        promise: axios.get(`/api/agency/lease/addresses?query=${query}`)
    };
}

/**
 * Disable submit button in form footer.
 *
 * @return {{type}}
 */
export function disableSubmitButton() {
    return {
        type: Lease.LEASE_FOOTER_DISABLE_SUBMIT
    };
}

/**
 * Enable submit button in form footer.
 *
 * @return {{type}}
 */
export function enableSubmitButton() {
    return {
        type: Lease.LEASE_FOOTER_ENABLE_SUBMIT
    };
}

export function clearValidationErrors(formPart) {
    return {
        type: Lease.LEASE_CLEAR_VALIDATION_ERRORS,
        formPart
    };
}

export function resolveRequest(request) {
    return {
        types: [Lease.REQUEST_RESOLVE_REQUEST, Lease.REQUEST_RESOLVE_SUCCESS, Lease.REQUEST_RESOLVE_FAIL],
        promise: axios.put(`api/agency/request-to-change/${request.id}/resolve`)
    };
}

export function unResolveRequest(request) {
    return {
        types: [Lease.REQUEST_UNRESOLVE_REQUEST, Lease.REQUEST_UNRESOLVE_SUCCESS, Lease.REQUEST_UNRESOLVE_FAIL],
        promise: axios.put(`api/agency/request-to-change/${request.id}/unresolve`)
    };
}

export function emailLeasePDF(leaseId, data) {
    return {
        types: [Lease.EMAIL_LEASE_PDF_REQUEST, Lease.EMAIL_LEASE_PDF_SUCCESS, Lease.EMAIL_LEASE_PDF_FAIL],
        promise: axios.post(`api/agency/lease/${leaseId}/pdf`, data)
    };
}

export function resetLeasePdfEmailSentSuccess() {
    return {
        type: Lease.RESET_EMAIL_LEASE_PDF
    };
}

export function emailLeaseForManualSigning(leaseId, data) {
    return {
        types: [
            Lease.EMAIL_LEASE_MANUAL_SIGNING_REQUEST,
            Lease.EMAIL_LEASE_MANUAL_SIGNING_SUCCESS,
            Lease.EMAIL_LEASE_MANUAL_SIGNING_FAIL
        ],
        promise: axios.post(`api/agency/lease/${leaseId}/manual-signing`, data)
    };
}

export function moveToArchive(leaseId) {
    return {
        types: [Lease.MOVE_TO_ARCHIVE_REQUEST, Lease.MOVE_TO_ARCHIVE_SUCCESS, Lease.MOVE_TO_ARCHIVE_FAIL],
        promise: axios.post(`api/agency/lease/${leaseId}/move-to-archive`)
    };
}
export function moveToAwaitingRenewal(leaseId) {
    return {
        types: [
            Lease.MOVE_TO_AWAITING_RENEWAL_REQUEST,
            Lease.MOVE_TO_AWAITING_RENEWAL_SUCCESS,
            Lease.MOVE_TO_AWAITING_RENEWAL_FAIL
        ],
        promise: axios.post(`api/agency/lease/${leaseId}/move-to-awaiting-renewal`)
    };
}

export function terminateLease(leaseId, data) {
    return {
        types: [
            Lease.SEND_TERMINATE_LEASE_REQUEST,
            Lease.SEND_TERMINATE_LEASE_SUCCESS,
            Lease.SEND_TERMINATE_LEASE_FAIL
        ],
        promise: axios.post(`api/agency/lease/${leaseId}/terminate`, data)
    };
}

export function createRentIncreaseSuccess(lease) {
    return {
        type: Lease.CREATE_RENT_INCREASE_PDF_SUCCESS,
        payload: {
            lease
        }
    };
}

export function addNoteSuccess(lease) {
    return {
        type: Lease.ADD_NOTE_SUCCESS,
        payload: {
            lease
        }
    };
}

export function createEspLetterSuccess(lease) {
    return {
        type: Lease.CREATE_ESP_LETTER_PDF_SUCCESS,
        payload: {
            lease
        }
    };
}

export function updateTerminateNoticeSenderEmailAddresses(emails) {
    return {
        type: Lease.UPDATE_TERMINATE_NOTICE_SENDER_EMAIL_ADDRESSES,
        payload: {
            emails
        }
    };
}

export function emailLeaseTerminationPDF(leaseId, leaseTerminationId) {
    return axios.post(`api/agency/lease/${leaseId}/${leaseTerminationId}/pdf/email-termination-notice`);
}

export function emailOwnerCopyToAgent(leaseId, data) {
    return {
        types: [
            Lease.EMAIL_OWNER_COPY_AGENT_REQUEST,
            Lease.EMAIL_OWNER_COPY_AGENT_SUCCESS,
            Lease.EMAIL_OWNER_COPY_AGENT_FAIL
        ],
        promise: axios.post(`api/agency/lease/${leaseId}/pdf/owner`, data)
    };
}

export function sendCompletedOwnerCopyToAgent(leaseId, data) {
    return {
        types: [
            Lease.EMAIL_COMPLETED_OWNER_COPY_AGENT_REQUEST,
            Lease.EMAIL_COMPLETED_OWNER_COPY_AGENT_SUCCESS,
            Lease.EMAIL_COMPLETED_OWNER_COPY_AGENT_FAIL
        ],
        promise: axios.post(`api/agency/lease/${leaseId}/pdf/completed-owner`, data)
    };
}

export function resetLeaseForManualSigningEmail() {
    return {
        type: Lease.RESET_LEASE_MANUAL_SIGNING
    };
}

export function emailRentIncreasePDF(leaseId) {
    return {
        types: [
            Lease.EMAIL_RENT_INCREASE_PDF_REQUEST,
            Lease.EMAIL_RENT_INCREASE_PDF_SUCCESS,
            Lease.EMAIL_RENT_INCREASE_PDF_FAIL
        ],
        promise: axios.post(`api/agency/lease/${leaseId}/pdf/rent-increase-letter`)
    };
}

export function setCurrentLeaseType(leaseType) {
    return {
        type: Lease.SET_LEASE_AGREEMENT_TYPE,
        payload: {
            leaseType
        }
    };
}

export function setCurrentDocumentType(docType) {
    return {
        type: Lease.SET_DOCUMENT_TYPE,
        payload: {
            docType
        }
    };
}

export function resetRentIncreasePdfEmail() {
    return {
        type: Lease.RESET_EMAIL_RENT_INCREASE_PDF
    };
}

export function resetOwnerCopyEmail() {
    return {
        type: Lease.RESET_EMAIL_OWNER_COPY_AGENT
    };
}

export function resetOwnerCompleteCopy() {
    return {
        type: Lease.RESET_EMAIL_COMPLETED_OWNER_COPY_AGENT
    };
}

export function updateDocuments(files) {
    return {
        type: Documents.UPDATE_DOCUMENTS,
        payload: {
            files
        }
    };
}

export function finishLeaseEditing(fies) {
    return {
        type: Lease.FINISH_LEASE_EDITING
    };
}

export function getLandlordAndProgressSteps(leaseId) {
    return {
        types: [Lease.LEASE_LANDLORD_DETAILS_REQUEST, Lease.LEASE_LANDLORD_DETAILS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.get(`api/agency/lease/${leaseId}/landlord-and-progress`)
    };
}

export function getVendorAndProgressSteps(leaseId) {
    return {
        types: [Lease.LEASE_VENDOR_DETAILS_REQUEST, Lease.LEASE_VENDOR_DETAILS_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.get(`api/agency/lease/${leaseId}/vendor-and-progress`)
    };
}

export function updateLandlord(leaseId, data, resendDeadline) {
    return {
        types: [Lease.UPDATE_LANDLORD_REQUEST, Lease.UPDATE_LANDLORD_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/update-landlord`, data),
        payload: {
            data: {
                deadline: resendDeadline
            }
        }
    };
}

export function updateVendor(leaseId, data, resendDeadline) {
    return {
        types: [Lease.UPDATE_VENDOR_REQUEST, Lease.UPDATE_VENDOR_SUCCESS, Lease.LEASE_FORM_FAIL],
        promise: axios.post(`/api/agency/lease/${leaseId}/update-vendor`, data),
        payload: {
            data: {
                deadline: resendDeadline
            }
        }
    };
}

export function updateLeaseInfo(lease) {
    return {
        type: Lease.UPDATE_LEASE_INFO,
        payload: {
            lease
        }
    };
}

export function removeSuggestion(suggestionKey, index) {
    return {
        type: Lease.REMOVE_SUGGESTION,
        suggestionKey,
        index
    };
}

export function updateSuggestion(suggestions) {
    return {
        type: Lease.UPDATE_SUGGESTION,
        payload: {
            suggestions
        }
    };
}

export function setLocation(location) {
    return {
        type: Lease.SET_LOCATION,
        payload: location
    };
}
