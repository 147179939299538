import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';

import useOpenCreationModal from '../../hooks/useOpenCreationModal';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_SALES,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_TERMINATION_NOTICE,
    routes,
    DOCUMENT_CUSTOM,
    DOCUMENT_CREATE_A_FLK,
    DISCLOSURE_DOCUMENT,
    BREACH_NOTICE,
    ENTRY_NOTICE,
    FLK_A_PDF_DISPLAY,
    CREATE_A_FLK_DISPLAY,
    LEASE_TYPE_HOLIDAY_LETTING_DISPLAY,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    NSW_STATE
} from '../../config';
import '../../sass/leftMenuDashboard.scss';
import ExpandLessIcon from '@material-ui/icons/ExpandLessSharp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreSharp';
import TrendingDownIcon from '@material-ui/icons/TrendingDownSharp';
import TrendingUpIcon from '@material-ui/icons/TrendingUpSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import {
    LEASE_STATUS_DRAFT,
    closeMenu,
    toggleAgreementsMenu,
    toggleDocumentsMenu,
    toggleIntentionToSellDocsMenu,
    FLK_A_KEY_STATUS_DRAFT
} from '../../actions/dashboard';
import SettingsIcon from '@material-ui/icons/SettingsSharp';
import HowToRegSharpIcon from '@material-ui/icons/HowToRegSharp';
import AnnouncementSharpIcon from '@material-ui/icons/AnnouncementSharp';
import CommercialLeaseIcon from '@material-ui/icons/BusinessSharp';
import { BarChartSharp, LibraryBooksSharp, PictureAsPdfSharp } from '@material-ui/icons';
import { getIsAgreementMenuOpen, getIsDocumentsMenuOpen } from '../../selectors/dashboard';
import { getAgencyDefaultLocation } from '../../selectors/settings/account';
import TerminationNotice from '../../common/components/icons/TerminationNotice';
import LeftMenuLink from '../../common/components/LeftMenuLink';

import { ReactComponent as ResidentialTenancyIcon } from '../../../assets/images/icons/tenancy.svg';
import { ReactComponent as PropertyManagementIcon } from '../../../assets/images/icons/case.svg';
import { ReactComponent as SalesIcon } from '../../../assets/images/icons/sales.svg';
import { ReactComponent as KeyIcon } from '../../../assets/images/icons/key.svg';
import { upperFirst } from 'lodash';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import {
    NSW_HOLIDAY_LETTING,
    NSW_ENTRY_NOTICE,
    QLD_BREACH_NOTICE,
    NSW_INTENTION_TO_SELL,
    TEMPLATE_SCREEN_V2,
    NSW_ESTIMATED_SELLING_PRICE_NOTICE,
    QLD_ENTRY_NOTICE,
    SALES_V2
} from '../../constants/featureFlags';
import Icon, { Icons } from '../../common/components/Icon';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../utils/agreementUtils';
import { openSubapp } from '../../utils/openSubapp';
import { HOLIDAY_LETTING_SUBAPP_PATH, SALES_SUBAPP_PATH } from '../../constants/constants';

const LeftMenu = props => {
    const isNswHolidayLettingActive = useFeatureFlag(NSW_HOLIDAY_LETTING);
    const isNswEntryNoticeActive = useFeatureFlag(NSW_ENTRY_NOTICE);
    const isQldEntryNoticeActive = useFeatureFlag(QLD_ENTRY_NOTICE);
    const isQldBreachNoticeActive = useFeatureFlag(QLD_BREACH_NOTICE);
    const isNswIntentionToSellActive = useFeatureFlag(NSW_INTENTION_TO_SELL);
    const isNswEstimatedSellingPriceNoticeActive = useFeatureFlag(NSW_ESTIMATED_SELLING_PRICE_NOTICE);
    const isTemplateScreenV2Active = useFeatureFlag(TEMPLATE_SCREEN_V2);
    const isSalesV2Active = useFeatureFlag(SALES_V2);
    const isAgreementsOpen = useSelector(getIsAgreementMenuOpen);
    const isDocumentsOpen = useSelector(getIsDocumentsMenuOpen);
    const location = useSelector(getAgencyDefaultLocation);
    const dispatch = useDispatch();

    const {
        openLeaseAgreementCreationModal,
        openDocumentCreationModal,
        openFlkAKeyCreationModal,
        isLeaseTypeEnabled,
        isActiveUser
    } = useOpenCreationModal();

    const closeMenuHandler = () => {
        dispatch(closeMenu());
    };

    const toggleAgreementsMenuOpen = () => {
        dispatch(toggleAgreementsMenu(!isAgreementsOpen));
    };
    const toggleDocumentsMenuOpen = () => {
        dispatch(toggleDocumentsMenu(!isDocumentsOpen));
    };
    const handleClickOutside = () => {
        closeMenuHandler();
    };

    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const { leftMenu } = props;

    return (
        <nav ref={ref} className={leftMenu ? 'left-menu on' : 'left-menu'}>
            <div className="menu">
                <div className="item-menu" onClick={toggleAgreementsMenuOpen}>
                    <Link to={'#'}>AGREEMENTS</Link>
                    {isAgreementsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                {isAgreementsOpen && (
                    <div className="section-wrapper">
                        <ul>
                            {isLeaseTypeEnabled(LEASE_TYPE_RESIDENTIAL) && (
                                <li>
                                    <LeftMenuLink
                                        label="Residential Tenancy"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${LEASE_STATUS_DRAFT}`}
                                        icon={<ResidentialTenancyIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openLeaseAgreementCreationModal(LEASE_TYPE_RESIDENTIAL)
                                                : null
                                        }
                                        tooltipText="Create a Residential tenancy agreement"
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_SALES) && (
                                <>
                                    {isSalesV2Active ? (
                                        <li>
                                            <LeftMenuLink
                                                label="Sales"
                                                onClick={closeMenuHandler}
                                                isActive={(match, location) => {
                                                    return location.pathname.startsWith(
                                                        `/dashboard/agreements/${LEASE_TYPE_SALES}`
                                                    );
                                                }}
                                                toPath={`/dashboard/agreements/${LEASE_TYPE_SALES}/${LEASE_STATUS_DRAFT}`}
                                                icon={<SalesIcon />}
                                                onCreateNew={
                                                    isActiveUser()
                                                        ? () =>
                                                              location === NSW_STATE
                                                                  ? openSubapp(SALES_SUBAPP_PATH)
                                                                  : openLeaseAgreementCreationModal(LEASE_TYPE_SALES)
                                                        : null
                                                }
                                                tooltipText="Create a Private Treaty or Auction agreement"
                                            />
                                        </li>
                                    ) : (
                                        <li>
                                            <LeftMenuLink
                                                label="Sales"
                                                onClick={closeMenuHandler}
                                                isActive={(match, location) => {
                                                    return location.pathname.startsWith(
                                                        `/dashboard/agreements/${LEASE_TYPE_SALES}`
                                                    );
                                                }}
                                                toPath={`/dashboard/agreements/${LEASE_TYPE_SALES}/${LEASE_STATUS_DRAFT}`}
                                                icon={<SalesIcon />}
                                                onCreateNew={
                                                    isActiveUser()
                                                        ? () => openLeaseAgreementCreationModal(LEASE_TYPE_SALES)
                                                        : null
                                                }
                                                tooltipText="Create a Private Treaty or Auction agreement"
                                            />
                                        </li>
                                    )}
                                </>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_PROPERTY_MANAGEMENT) && (
                                <li>
                                    <LeftMenuLink
                                        label="Property Management"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_PROPERTY_MANAGEMENT}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_PROPERTY_MANAGEMENT}/${LEASE_STATUS_DRAFT}`}
                                        icon={<PropertyManagementIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openLeaseAgreementCreationModal(LEASE_TYPE_PROPERTY_MANAGEMENT)
                                                : null
                                        }
                                        tooltipText="Create a Managing agency agreement"
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_COMMERCIAL_LEASE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Commercial Lease"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_COMMERCIAL_LEASE}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_COMMERCIAL_LEASE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<CommercialLeaseIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openLeaseAgreementCreationModal(LEASE_TYPE_COMMERCIAL_LEASE)
                                                : null
                                        }
                                        tooltipText="Create a Commercial Lease"
                                    />
                                </li>
                            )}
                            {isNswHolidayLettingActive && isLeaseTypeEnabled(LEASE_TYPE_HOLIDAY_LETTING) && (
                                <li>
                                    <LeftMenuLink
                                        label={LEASE_TYPE_HOLIDAY_LETTING_DISPLAY}
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/agreements/${LEASE_TYPE_HOLIDAY_LETTING}`
                                            );
                                        }}
                                        toPath={`/dashboard/agreements/${LEASE_TYPE_HOLIDAY_LETTING}/${LEASE_STATUS_DRAFT}`}
                                        icon={<Icon icon={Icons.HOLIDAY_HOUSE} />}
                                        onCreateNew={
                                            isActiveUser() ? () => openSubapp(HOLIDAY_LETTING_SUBAPP_PATH) : null
                                        }
                                        tooltipText="Create a Holiday letting agreement"
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                <div className="item-menu" onClick={toggleDocumentsMenuOpen}>
                    <Link to={'#'}>DOCUMENT CENTRE</Link>
                    {isDocumentsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                {isDocumentsOpen && (
                    <div className="section-wrapper">
                        <ul>
                            <li>
                                <LeftMenuLink
                                    label="Rent Relief"
                                    onClick={closeMenuHandler}
                                    isActive={(match, location) => {
                                        return location.pathname.startsWith(
                                            `/dashboard/documents/${DOCUMENT_RENT_REDUCTION}`
                                        );
                                    }}
                                    toPath={`/dashboard/documents/${DOCUMENT_RENT_REDUCTION}/${LEASE_STATUS_DRAFT}`}
                                    icon={<TrendingDownIcon />}
                                    onCreateNew={
                                        isActiveUser() ? () => openDocumentCreationModal(DOCUMENT_RENT_REDUCTION) : null
                                    }
                                    tooltipText="Create a rent relief or rent discount document"
                                />
                            </li>
                            {isLeaseTypeEnabled(DOCUMENT_TERMINATION_NOTICE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Termination Notice"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_TERMINATION_NOTICE}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DOCUMENT_TERMINATION_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<TerminationNotice />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(DOCUMENT_TERMINATION_NOTICE)
                                                : null
                                        }
                                        tooltipText="Create a termination notice"
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_RENT_INCREASE) && (
                                <li>
                                    <LeftMenuLink
                                        label="Rent Increase Notice"
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_RENT_INCREASE}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DOCUMENT_RENT_INCREASE}/${LEASE_STATUS_DRAFT}`}
                                        icon={<TrendingUpIcon />}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(DOCUMENT_RENT_INCREASE)
                                                : null
                                        }
                                        tooltipText="Create a rent increase notice"
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(BREACH_NOTICE) &&
                                (isQldBreachNoticeActive || !isLeaseLocationQLD(location)) && (
                                    <li>
                                        <LeftMenuLink
                                            label="Breach Notice"
                                            onClick={closeMenuHandler}
                                            active={(match, location) => {
                                                return location.pathname.startsWith(
                                                    `/dashboard/documents/${BREACH_NOTICE}`
                                                );
                                            }}
                                            toPath={`/dashboard/documents/${BREACH_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                            icon={<AnnouncementSharpIcon />}
                                            onCreateNew={
                                                isActiveUser() ? () => openDocumentCreationModal(BREACH_NOTICE) : null
                                            }
                                            tooltipText="Create a breach notice"
                                        />
                                    </li>
                                )}

                            {/* /* TODO: REMOVE it when clean up the feature flags */}
                            {((isNswEntryNoticeActive && isLeaseLocationNSW(location)) ||
                                (isQldEntryNoticeActive && isLeaseLocationQLD(location))) &&
                                isLeaseTypeEnabled(ENTRY_NOTICE) && (
                                    <li>
                                        <LeftMenuLink
                                            label="Entry Notice"
                                            onClick={closeMenuHandler}
                                            active={(match, location) => {
                                                return location.pathname.startsWith(
                                                    `/dashboard/documents/${ENTRY_NOTICE}`
                                                );
                                            }}
                                            toPath={`/dashboard/documents/${ENTRY_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                            icon={<Icon icon={Icons.ENTRY_DOOR} />}
                                            onCreateNew={
                                                isActiveUser() ? () => openDocumentCreationModal(ENTRY_NOTICE) : null
                                            }
                                            tooltipText="Create an entry notice"
                                        />
                                    </li>
                                )}
                            {isNswIntentionToSellActive &&
                                isLeaseTypeEnabled(DOCUMENT_INTENTION_TO_SELL) &&
                                isLeaseLocationNSW(location) && (
                                    <li>
                                        <LeftMenuLink
                                            label="Intention to sell notice"
                                            onClick={closeMenuHandler}
                                            active={(match, location) => {
                                                return location.pathname.startsWith(
                                                    `/dashboard/documents/${DOCUMENT_INTENTION_TO_SELL}`
                                                );
                                            }}
                                            toPath={`/dashboard/documents/${DOCUMENT_INTENTION_TO_SELL}/${LEASE_STATUS_DRAFT}`}
                                            icon={<Icon icon={Icons.MEGAPHONE} />}
                                            onCreateNew={() => openDocumentCreationModal(DOCUMENT_INTENTION_TO_SELL)}
                                            tooltipText="Create a intention to sell notice"
                                        />
                                    </li>
                                )}
                            {isNswEstimatedSellingPriceNoticeActive &&
                                isLeaseTypeEnabled(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE) &&
                                isLeaseLocationNSW(location) && (
                                    <li>
                                        <LeftMenuLink
                                            label="Estimated selling price notice"
                                            onClick={closeMenuHandler}
                                            active={(match, location) => {
                                                return location.pathname.startsWith(
                                                    `/dashboard/documents/${DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}`
                                                );
                                            }}
                                            toPath={`/dashboard/documents/${DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}/${LEASE_STATUS_DRAFT}`}
                                            icon={<Icon icon={Icons.ESP} />}
                                            onCreateNew={() =>
                                                openDocumentCreationModal(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE)
                                            }
                                            tooltipText="Create an estimated selling pricing notice"
                                        />
                                    </li>
                                )}
                            {isLeaseTypeEnabled(DISCLOSURE_DOCUMENT) && (
                                <li>
                                    <LeftMenuLink
                                        label="Disclosure Document - VIC"
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DISCLOSURE_DOCUMENT}`
                                            );
                                        }}
                                        toPath={`/dashboard/documents/${DISCLOSURE_DOCUMENT}/${LEASE_STATUS_DRAFT}`}
                                        icon={<HowToRegSharpIcon />}
                                        onCreateNew={
                                            isActiveUser() ? () => openDocumentCreationModal(DISCLOSURE_DOCUMENT) : null
                                        }
                                        tooltipText="Create a disclosure document"
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_CUSTOM) && (
                                <li>
                                    <LeftMenuLink
                                        label={FLK_A_PDF_DISPLAY}
                                        onClick={closeMenuHandler}
                                        isActive={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_CUSTOM}`
                                            );
                                        }}
                                        icon={<PictureAsPdfSharp />}
                                        toPath={`/dashboard/documents/${DOCUMENT_CUSTOM}/${LEASE_STATUS_DRAFT}`}
                                        onCreateNew={
                                            isActiveUser() ? () => openDocumentCreationModal(DOCUMENT_CUSTOM) : null
                                        }
                                        tooltipText={`${upperFirst(
                                            FLK_A_PDF_DISPLAY
                                        )} will allow you to upload any PDF and send it to your client for signing or acknowledgement via email or SMS`}
                                    />
                                </li>
                            )}
                            {isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK) && (
                                <li>
                                    <LeftMenuLink
                                        label={CREATE_A_FLK_DISPLAY}
                                        onClick={closeMenuHandler}
                                        active={(match, location) => {
                                            return location.pathname.startsWith(
                                                `/dashboard/documents/${DOCUMENT_CREATE_A_FLK}`
                                            );
                                        }}
                                        icon={<DescriptionSharpIcon />}
                                        toPath={`/dashboard/documents/${DOCUMENT_CREATE_A_FLK}/${LEASE_STATUS_DRAFT}`}
                                        onCreateNew={
                                            isActiveUser()
                                                ? () => openDocumentCreationModal(DOCUMENT_CREATE_A_FLK)
                                                : null
                                        }
                                        tooltipText={`${upperFirst(
                                            CREATE_A_FLK_DISPLAY
                                        )} allows you to create any document or agreement, input required data and send it to your client for review and sign via email or SMS`}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {
                    <div className="section-wrapper">
                        <LeftMenuLink
                            label="FLK a Key"
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return location.pathname.startsWith('/dashboard/flk-a-key');
                            }}
                            toPath={`/dashboard/flk-a-key/${FLK_A_KEY_STATUS_DRAFT}`}
                            icon={<KeyIcon />}
                            onCreateNew={isActiveUser() ? () => openFlkAKeyCreationModal() : null}
                            tooltipText="FLK a Key allows you to track keys that are checked out, upload pictures of keys and chase any overdue keys whilst keeping everyone in the loop"
                        />
                    </div>
                }
                <hr className="menu-break" />
                <ul>
                    {isTemplateScreenV2Active && (
                        <li>
                            <LeftMenuLink
                                label={'Templates'}
                                onClick={closeMenuHandler}
                                isActive={(match, location) => {
                                    return location.pathname.startsWith('templates');
                                }}
                                icon={<LibraryBooksSharp />}
                                toPath={routes.ROUTE_TEMPLATES}
                            />
                        </li>
                    )}
                    <li>
                        <LeftMenuLink
                            label={'Settings'}
                            onClick={closeMenuHandler}
                            isActive={(match, location) => {
                                return (
                                    location.pathname.startsWith(`/user`) &&
                                    !location.pathname.startsWith('/user/statistics')
                                );
                            }}
                            icon={<SettingsIcon />}
                            toPath={`/user`}
                        />
                    </li>
                    <li>
                        <LeftMenuLink
                            label="Statistics"
                            onClick={closeMenuHandler}
                            toPath={routes.ROUTE_STATISTICS}
                            icon={<BarChartSharp />}
                        />
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default memo(LeftMenu);
