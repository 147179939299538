import axios from 'axios';
import {
    HANDLE_COMPLETION_FORM_CHANGE,
    COMPLETION_FORM_ADD_EMAIL,
    COMPLETION_FORM_DELETE_EMAIL,
    COMPLETION_FORM_RESET_TO_DEFAULT,
    GET_WITNESSES_REQUEST,
    GET_WITNESSES_SUCCESS,
    GET_WITNESSES_FAILURE,
    GET_WITNESSES_SIGNATURE_REQUEST,
    GET_WITNESSES_SIGNATURE_SUCCESS,
    GET_WITNESSES_SIGNATURE_FAILURE,
    GET_AGENT_SIGNATURE_REQUEST,
    GET_AGENT_SIGNATURE_SUCCESS,
    GET_AGENT_SIGNATURE_FAILURE,
    COMPLETION_FORM_SET_EMAIL
} from '../reducers/completion';

export function getWitnesses() {
    return {
        types: [GET_WITNESSES_REQUEST, GET_WITNESSES_SUCCESS, GET_WITNESSES_FAILURE],
        promise: axios.get('/api/agency/team/witnesses')
    };
}

export function getWitnessSignature(witnessId, password) {
    return {
        types: [GET_WITNESSES_SIGNATURE_REQUEST, GET_WITNESSES_SIGNATURE_SUCCESS, GET_WITNESSES_SIGNATURE_FAILURE],
        promise: axios.post(`/api/agency/account/signatures/witness/${witnessId}`, { password })
    };
}

export function handleCompletionFormChange(data) {
    return {
        type: HANDLE_COMPLETION_FORM_CHANGE,
        data
    };
}

export function addEmail(listType, email) {
    return {
        type: COMPLETION_FORM_ADD_EMAIL,
        payload: {
            listType,
            email
        }
    };
}
export function setEmail(listType, email) {
    return {
        type: COMPLETION_FORM_SET_EMAIL,
        payload: {
            listType,
            email
        }
    };
}

export function deleteEmail(listType, index) {
    return {
        type: COMPLETION_FORM_DELETE_EMAIL,
        payload: {
            listType,
            index
        }
    };
}

export function resetToDefault() {
    return {
        type: COMPLETION_FORM_RESET_TO_DEFAULT
    };
}

export function getAgentSignature(userId) {
    return {
        types: [GET_AGENT_SIGNATURE_REQUEST, GET_AGENT_SIGNATURE_SUCCESS, GET_AGENT_SIGNATURE_FAILURE],
        promise: axios.get(`/api/agency/account/signatures/${userId}`)
    };
}
