import React, { useEffect, useState } from 'react';
import Warning from '../../../../components/dashboard/warnings/Warning.js';
import { EntryReasonsType } from './EntryReasons';
import { isLeaseLocationNSW, isLeaseLocationQLD } from '../../../../utils/agreementUtils.js';
import { getTimeDifferenceInHours } from '../../../../utils/dateUtils.js';
import { differenceInDays, isToday } from 'date-fns';
import styles from './WarningMessage.module.scss';

interface ExtendedEntryReasonsType extends EntryReasonsType {
    dateOfEntry: Date;
    timeOfEntry: string;
}

type WarningMessageProps = {
    values: ExtendedEntryReasonsType;
    location: 'QLD' | 'NSW';
};

const TODAY = new Date();

export const WarningMessage = ({ values, location }: WarningMessageProps) => {
    const [warnings, setWarnings] = useState<string[]>([]);

    useEffect(() => {
        const newWarnings: string[] = [];

        if (values.dateOfEntry) {
            const isDateBeforeDays = (date: Date, days: number): boolean => {
                if (days === 1) {
                    return isToday(date);
                }

                // We need to subtract 1 from the days because the differenceInDays function is inclusive of the start date
                return differenceInDays(date, TODAY) < days - 1;
            };

            if (isLeaseLocationNSW(location)) {
                if (isDateBeforeDays(values.dateOfEntry, 7) && values.toInspectPremises) {
                    newWarnings.push('7 days notice is required to inspect the premises.');
                }
                if (isDateBeforeDays(values.dateOfEntry, 2) && values.toInspectRepairs) {
                    newWarnings.push(
                        '2 days notice is required to carry out or assess the need for maintenance or repairs.'
                    );
                }
                if (isDateBeforeDays(values.dateOfEntry, 2) && values.toShowProspectivePurchaser) {
                    newWarnings.push('2 days notice is required to show the premise to a prospective purchaser.');
                }
                if (isDateBeforeDays(values.dateOfEntry, 7) && values.toValueProperty) {
                    newWarnings.push('7 days notice is required to value the property.');
                }
            } else if (isLeaseLocationQLD(location)) {
                const differenceInHours = getTimeDifferenceInHours(values.dateOfEntry, values.timeOfEntry);
                // Check the date of entry with the time of entry is longer than 7 days in hours.
                if (values.toInspectPremises && differenceInHours < 24 * 7) {
                    newWarnings.push(
                        'Please ensure the entry date and time are set at least 7 FULL days after the notice is issued. Day one of the notice period begins the day after its issued.'
                    );
                }
                if (
                    // Check the date of entry with the time of entry is longer than 24 hours.
                    differenceInHours < 24 &&
                    (values.toInspectShortTenancyMoveableDwelling ||
                        values.toCarryOutRoutineRepairs ||
                        values.toInspectRepairs ||
                        values.toComplyWithFireAndSafety ||
                        values.toInstallSmokeAlarm ||
                        values.toComplyWithElectricalSafety ||
                        values.toShowProspectiveTenant ||
                        values.toValueProperty ||
                        values.toCheckIfPropertyIsAbandoned ||
                        values.toCheckRemediedBreach)
                ) {
                    newWarnings.push(
                        'Make sure the entry date and time are at least a full 24 hours after the notice is issued.'
                    );
                }
            }
        }
        setWarnings(newWarnings.slice(0, 2)); // Max two warnings
    }, [values.dateOfEntry, values.dateOfEntry, values, location]);

    return (
        <div className={styles.container}>
            {warnings.map((warning, index) => (
                <Warning key={index}>
                    <i className="icon-warning" />
                    <span>{warning}</span>
                </Warning>
            ))}
        </div>
    );
};
