import React from 'react';
import { cloneDeep } from 'lodash';
import { FormTextHidden } from '../../../form/FormText';
import { rentPeriods as period, PERIOD_MONTHLY, formatPeriod } from '../../../../config';
import { addDays } from 'date-fns';
import { getResTenLabel } from '../../../../utils/labelUtils';
import { isLongTermRenewalSubLease, getRentForPeriod } from '../../../../utils/agreementUtils';
import RentAmount from '../../../form/RentAmount.tsx';
import SuggestionTextField from '../../../form/SuggestionTextField';
import SuggestionSelectField from '../../../form/SuggestionSelectField';
import SuggestionDatePicker from '../../../form/SuggestionDatePicker';

const RentIncreaseGroup = ({
    index,
    location,
    rent,
    setRent,
    values,
    calcRentMonthly,
    removeRentIncrease,
    multipleRentIncrease,
    roundDownNewMonthlyRent,
    roundUpNewMonthlyRent,
    handleNewRentAmountChange,
    subLeaseType
}) => {
    return (
        <div className="item-condition">
            <div className="clauses">
                {multipleRentIncrease && (
                    <div className="clauses-header">
                        <h3>Rent Increase {index + 1}</h3>
                        {index > 0 && (
                            <div className="toggle" onClick={() => removeRentIncrease(index)}>
                                <span className="span-remove">Remove</span>
                            </div>
                        )}
                    </div>
                )}
                <div className="rent-increase-data">
                    <div className="agreement-select">
                        <div>
                            <SuggestionTextField
                                name={`rentIncreaseList[${index}].rentIncrease.newRentAmount`}
                                label="New rent per week is"
                                onChange={value => {
                                    handleNewRentAmountChange(value, index, values);
                                }}
                                required
                                formatSuggestions={value => value.toFixed(2)}
                                suggestionsFieldName={`rentIncreases[${index}].amount`}
                                allowSelectMultipleSuggestions={false}
                            />
                            <FormTextHidden name={`rentIncreaseList[${index}].rentIncrease.newRentAmount`} />
                        </div>
                        <div>
                            <SuggestionSelectField
                                label={getResTenLabel('payable', location)}
                                name={`rentIncreaseList[${index}].rentIncrease.newRentPayablePeriod`}
                                options={period}
                                suggestionsFieldName={`rentIncreases[${index}].period`}
                                onChange={value => {
                                    const rentIncreaseList = cloneDeep(values.rentIncreaseList);
                                    if (rentIncreaseList[index] && rentIncreaseList[index].rentIncrease) {
                                        rentIncreaseList[index].rentIncrease.newRentPayablePeriod = value.value;
                                        rentIncreaseList[index].rentIncrease.newRentMonthly = parseFloat(
                                            calcRentMonthly(
                                                rentIncreaseList[index].rentIncrease.newRentAmount,
                                                value.value
                                            )
                                        ).toFixed(2);
                                    }
                                    setRent({
                                        ...values,
                                        rentIncreaseList
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {rent.rentIncreaseList[index] &&
                        rent.rentIncreaseList[index].rentIncrease &&
                        rent.rentIncreaseList[index].rentIncrease.newRentPayablePeriod === PERIOD_MONTHLY &&
                        !!rent.rentIncreaseList[index].rentIncrease.newRentMonthly && (
                            <p className="help-text big">
                                The monthly rent is
                                <span className="button-minus" onClick={() => roundDownNewMonthlyRent(index)} />
                                <span>{rent.rentIncreaseList[index].rentIncrease.newRentMonthly}</span>
                                <span className="button-plus" onClick={() => roundUpNewMonthlyRent(index)} />
                            </p>
                        )}
                    {rent?.rentIncreaseList[index]?.rentIncrease?.newRentAmount &&
                        rent?.rentIncreaseList[index]?.rentIncrease?.newRentPayablePeriod && (
                            <RentAmount
                                label="The tenant will pay"
                                value={`$${getRentForPeriod(
                                    parseFloat(rent.rentIncreaseList[index].rentIncrease.newRentAmount),
                                    rent.rentIncreaseList[index].rentIncrease.newRentPayablePeriod
                                )} per ${formatPeriod(rent.rentIncreaseList[index].rentIncrease.newRentPayablePeriod)}`}
                            />
                        )}
                    <div className="calendar">
                        <SuggestionDatePicker
                            label={
                                isLongTermRenewalSubLease(subLeaseType)
                                    ? 'First payment due on'
                                    : getResTenLabel('effectiveFrom', location)
                            }
                            name={`rentIncreaseList[${index}].rentIncrease.newRentDateStart`}
                            selected={rent.rentIncreaseList[index].rentIncrease.newRentDateStart}
                            minValue={
                                // for normal agreements it makes sense to only allow rent increases after the lease
                                // startdate,but for SA lease extension its not a rent increase during the period,
                                // so it can be the same day as the lease starts, and it often would be
                                isLongTermRenewalSubLease(subLeaseType)
                                    ? new Date(rent.dateStart)
                                    : addDays(new Date(rent.dateStart), 1)
                            }
                            suggestionsFieldName={`rentIncreases[${index}].startDate`}
                            onChange={value => {
                                const rentIncreaseList = cloneDeep(values.rentIncreaseList);
                                rentIncreaseList[index].rentIncrease.newRentDateStart = value;
                                setRent({
                                    ...values,
                                    rentIncreaseList
                                });
                            }}
                            required
                            popperPlacement="top-start"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RentIncreaseGroup;
