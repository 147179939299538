import React, { useEffect, useState } from 'react';
import Button from '../../common/components/Button';
import axios from 'axios';
import { Table, Th, Tr, Thead, Td } from 'reactable';
import { SelectField } from '../../components/form/FormSelect';
import { useSelector, useDispatch } from 'react-redux';
import { getAllowedTemplateList } from '../../selectors/dashboard';
import { getTemplatesForSelect } from '../../actions/template';
import { getAgencyLocation } from '../../selectors/user';
import { TEMPLATE_TYPE_PROPERTY_MANAGEMENT } from '../../config';
import { Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { BULK_PM_IMPORT } from '../../constants/featureFlags';

type PropertyForImport = {
    externalId: string;
    address: string;
    ownerName: string;
    location: string;
};

type ImportTableProps = {
    properties: [PropertyForImport];
    selectedProperties: Set<string>;
    setSelectedProperties: React.Dispatch<React.SetStateAction<Set<string>>>;
    location?: string;
};
const ImportTable: React.FC<ImportTableProps> = ({
    properties,
    selectedProperties,
    setSelectedProperties,
    location
}) => {
    const propertiesForLocation = properties.filter(property => property.location === location);
    const toggleAll = () => {
        const newProperties = new Set<string>();
        if (selectedProperties.size === propertiesForLocation.length) {
            setSelectedProperties(new Set<string>());
            return;
        }
        propertiesForLocation.map(property => {
            if (property.location === location) {
                newProperties.add(property.externalId);
            }
            return;
        });
        setSelectedProperties(newProperties);
    };

    const toggleProperty = (_event: React.MouseEvent<HTMLInputElement, MouseEvent>, property: PropertyForImport) => {
        const newProperties = new Set([...selectedProperties]);
        if (!selectedProperties.has(property.externalId)) {
            newProperties.add(property.externalId);
        } else {
            newProperties.delete(property.externalId);
        }
        setSelectedProperties(newProperties);
    };

    return (
        <div className="table-sort">
            <div className="action-buttons">
                <input
                    className="checkbox-select"
                    type="checkbox"
                    onClick={toggleAll}
                    checked={propertiesForLocation.length === selectedProperties.size}
                />
                <p>
                    {/*todo add left padding to this*/}
                    <b>{selectedProperties.size} selected for import</b>
                </p>
            </div>
            <Table>
                <Thead>
                    <Th column="select">&nbsp;</Th>
                    <Th column="address">Address</Th>
                    <Th column="owner-name">Owner Name</Th>
                </Thead>
                {Array.isArray(propertiesForLocation) &&
                    propertiesForLocation.map((property, index) => {
                        return (
                            <Tr key={index}>
                                <Td column="select">
                                    <input
                                        className="checkbox-select"
                                        type="checkbox"
                                        onClick={e => toggleProperty(e, property)}
                                        checked={selectedProperties.has(property.externalId)}
                                    />
                                </Td>
                                <Td column="address">
                                    <b>{property.address}</b>
                                </Td>
                                <Td column="owner-name">
                                    <b>{property.ownerName}</b>
                                </Td>
                            </Tr>
                        );
                    })}
            </Table>
        </div>
    );
};

const BulkImport = () => {
    // todo add a feature flag here to show the promo message and disable the button entirely
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProperties, setSelectedProperties] = useState(new Set<string>());
    const [properties, setProperties] = useState<[PropertyForImport]>();

    const agencyLocation = useSelector(getAgencyLocation);
    const dispatch = useDispatch();
    const bulkImportFlag = useFeatureFlag(BULK_PM_IMPORT);

    useEffect(() => {
        if (agencyLocation) {
            dispatch(getTemplatesForSelect(TEMPLATE_TYPE_PROPERTY_MANAGEMENT, agencyLocation));
        }
    }, [dispatch, agencyLocation]);

    const allowedTemplateList = useSelector(getAllowedTemplateList);

    const getPropertiesForImport = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/integrations/bulk-import');
            setProperties(response.data.properties);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const showImportTable = properties && properties.length > 0;

    const submitImport = async (values: { template?: any }) => {
        // todo add error messaging for failure casees here and move this into final form to handle state
        const response = await axios.post('/api/integrations/bulk-import', {
            properties: [...selectedProperties],
            template: values.template
        });
    };
    const validate: ValidationErrors = (values: { template?: any[] }) => {
        // todo fix this error message to only be shown after a submission attempt
        return {
            template: !values.template || values.template.length < 1 ? 'Please select a template' : undefined
        };
    };

    switch (bulkImportFlag) {
        case 'MenuPreview':
            return (
                <div>
                    <h1>Bulk Import</h1>
                    <p>Interested? Please get in contact with our support team!</p>
                    {/* todo update this copy */}
                </div>
            );
        case 'Enabled':
            return (
                <div>
                    <h1>Bulk Import</h1>
                    <div>
                        {!showImportTable && (
                            <Button primary onClick={getPropertiesForImport} loading={isLoading}>
                                Start New Import
                            </Button>
                        )}
                    </div>
                    {/* The state of the import table will still be handled by react state for now */}
                    {showImportTable && (
                        <Form
                            onSubmit={submitImport}
                            validate={validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Button
                                        primary
                                        onClick={handleSubmit}
                                        disabled={selectedProperties.size < 1}
                                        loading={isLoading}
                                    >
                                        Import Properties
                                    </Button>
                                    <SelectField
                                        isSearchable={true}
                                        isClearable={true}
                                        name="template"
                                        options={allowedTemplateList}
                                        selectClassName="sel-4"
                                        placeholder="Select template"
                                        label={'Template'}
                                        required
                                    />
                                    <ImportTable
                                        properties={properties}
                                        selectedProperties={selectedProperties}
                                        setSelectedProperties={setSelectedProperties}
                                        location={agencyLocation}
                                    />
                                </form>
                            )}
                        />
                    )}
                </div>
            );
        default:
            return (
                <div>
                    <h1>Bulk Import</h1>
                    <br />
                    <p>Sorry this feature is currently disabled</p>
                    <br />
                    <p>Please check back later</p>
                    {/* todo update this copy */}
                </div>
            );
    }
};

export default BulkImport;
