import { ToastTypes } from '../common/components/Toast';
import useToast from './useToast';

const useClipboard = () => {
    const { addNewToast } = useToast();
    const copyToClipboard = (stringToCopy: string, toastString = 'Text Copied!', toastClassName?: string) => {
        navigator.clipboard.writeText(stringToCopy).then(() => {
            addNewToast(toastString, ToastTypes.SUCCESS, true, toastClassName);
        });
    };
    return {
        copyToClipboard
    };
};

export default useClipboard;
