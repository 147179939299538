import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { has } from 'lodash';

import { setDirtyStep, updateSubmitTypeSuccess, formSubmitFail } from '../../../../actions/lease';
import { FormTextRegular } from '../../../../components/form/FormText';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmAdditionalCosts } from '../../../../selectors/lease/pmLease';
import { CostGroup } from './CostGroup';
import * as Lease from '../../../../reducers/lease';
import { ReactComponent as plusIcon } from '../../../../../assets/images/icons/plus.svg';

const PM_ADDITIONAL_COSTS_FORM = 'pmAdditionalCostsForm';

const initState = {
    statementAdminFee: '$0',
    conditionReportVideoFee: '$0',
    endOfYearStatementFee: '$0',
    vcatApplicationFee: '$0',
    vcatPreparationFee: '$0',
    warrantFee: '$0',
    registeredPostFee: '$0',
    insuranceClaimFee: '$0',
    keyCuttingFee: '$0',
    additionalCostsList: []
};

function AdditionalCostsLayout(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmAdditionalCosts = useSelector(getPmAdditionalCosts);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmPmAdditionalCosts, setPmAdditionalCosts] = useState(reduxPmAdditionalCosts || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_ADDITIONAL_COSTS_FORM).dispatchEvent(new Event('submit', { cancelable: true }));
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        let payload = { ...values };
        return updatePmAdditionalCosts(payload)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmAdditionalCosts')) {
                    return error.response.data.errors.AdditionalCosts;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    function updatePmAdditionalCosts(data) {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-additional-costs`, data);
    }

    function addCost(values) {
        const additionalCostsList = values.additionalCostsList || [];
        let updatedFees = {
            ...values,
            additionalCostsList: [
                ...[...additionalCostsList],
                {
                    title: '',
                    amount: '$'
                }
            ]
        };
        setPmAdditionalCosts(updatedFees);
    }

    function removeCost(values, index) {
        const additionalCostsList = values.additionalCostsList || [];
        const updatedFees = {
            ...values,
            additionalCostsList: [...additionalCostsList.slice(0, index), ...additionalCostsList.slice(index + 1)]
        };
        setPmAdditionalCosts(updatedFees);
    }

    return (
        <div className="pm-costs-vic rent">
            <Form onSubmit={submitForm} initialValues={pmPmAdditionalCosts}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_ADDITIONAL_COSTS_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <p className="description">All fees listed below are inclusive of GST</p>
                            <div className="cost">
                                <FormTextRegular name="statementAdminFee" label="Administration Fee per Statement" />
                                <FormTextRegular name="conditionReportVideoFee" label="Condition Report Video" />
                                <FormTextRegular name="endOfYearStatementFee" label="End of Financial Year Statement" />
                                <FormTextRegular name="vcatApplicationFee" label="VCAT Application Fee" />
                                <FormTextRegular name="vcatPreparationFee" label="Preparation and attendance at VCAT" />
                                <FormTextRegular name="warrantFee" label="Obtaining Warrant of Possession" />
                                <FormTextRegular name="registeredPostFee" label="Registered Post" />
                                <FormTextRegular
                                    name="insuranceClaimFee"
                                    label="Preparing and Lodging Insurance Claim"
                                />
                                <FormTextRegular name="keyCuttingFee" label="Key Cutting" />
                            </div>
                            <div className="additional-costs">
                                {Array.isArray(pmPmAdditionalCosts.additionalCostsList) && (
                                    <div>
                                        {pmPmAdditionalCosts.additionalCostsList.map((item, index) => (
                                            <CostGroup
                                                index={index}
                                                key={index}
                                                removeFee={index => removeCost(values, index)}
                                                location={location}
                                                fee={item}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="button">
                                <button className="mobile add-item" type="button" onClick={() => addCost(values)}>
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add costs
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(AdditionalCostsLayout));
