import axios from 'axios';
import { useHistory } from 'react-router-dom';

export function useLegacyDashboardActions() {
    const history = useHistory();

    async function clone(agreementId: string) {
        const { data } = await axios.post(`/api/agency/lease/${agreementId}/clone`);

        history.push(`/dashboard/agreement/${data.lease.id}`);
    }

    async function performLegacyAction(action: string, agreementId: string) {
        switch (action) {
            case 'open':
                history.push(`/dashboard/agreement/${agreementId}`);
                break;
            case 'clone':
                await clone(agreementId);
                break;
            case 'delete':
                await axios.delete(`/api/agency/lease/${agreementId}`);
                break;
            case 'move-to-drafts':
                await axios.post(`/api/agency/lease/${agreementId}/cancel-signing`);
                break;
        }
    }

    return { performLegacyAction };
}
