import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import { formatDateTime, getAgencyTimezoneFromAgency } from './dateUtils';
import { isAdmin } from './userUtils';
import { AGENCY_ACCOUNT_TYPE } from '../constants/constants';

const getYesNo = (bool, checkForBoolean) => {
    if (checkForBoolean) {
        if (!isBoolean(bool)) {
            return undefined;
        }
    }
    return bool ? 'Yes' : 'No';
};
export const extractUserAndAgencyInfo = (userInfo, agency) => {
    const user = {};
    if (userInfo && userInfo.id) {
        user.key = userInfo.id;
        user.user_id = userInfo.id;
        user.userId = userInfo.id;
        user.status = userInfo.status;
        user.email = userInfo.email;
        user.role = userInfo.role;
        user.name = userInfo.firstName + ' ' + userInfo.secondName;
        user.created = formatDateTime(userInfo.created, getAgencyTimezoneFromAgency(agency));
        user.phone = userInfo.phone;
        user.contactNumber = userInfo.contactNumber;
        user.isAdmin = getYesNo(isAdmin(userInfo.role));
        user.build_number = BUILD_NUMBER;
        user.timezone = userInfo.timezone;
        user.currentMonthResTenCompleteCount = userInfo.currentMonthResTenCompleteCount;
        user.uniqueEmail = userInfo.uniqueEmail;
        user.emailVerified = userInfo.emailVerified;
    }

    if (agency && !isEmpty(agency) && agency.id && agency.details) {
        user.agencyId = agency.id;
        user.agencyName = agency.details.agencyName;
        user.companyName = agency.details.companyName;
        user.agencyAddress = agency.details.address;
        user.agencyLocation = agency.details.location;
        user.agencyCountry = agency.details.country;
        user.accountType = userInfo.accountType;
        user.accountTypeDescription = userInfo.accountType === AGENCY_ACCOUNT_TYPE ? 'agency' : 'business';
        if (agency.tariffPlan) {
            user.agencyPlan = agency.tariffPlan.title;
            user.agencyPlanStatus = agency.tariffPlan.status;
            user.agencyPlanActive = getYesNo(agency.tariffPlan.active);
            user.agencyPlanPaymentMethod = getYesNo(!!agency.stripeDefaultPaymentMethodId);
            user.agencyPlanStartDate = formatDateTime(
                agency.tariffPlan.currentPeriodStart,
                getAgencyTimezoneFromAgency(agency)
            );
            user.agencyPlanEndDate = formatDateTime(
                agency.tariffPlan.currentPeriodEnd,
                getAgencyTimezoneFromAgency(agency)
            );
        }
        user.agencyModules = agency.productModules.map(mod => mod.productKey).join(',') || 'None';
        user.integration = getIntegration(agency);
        user.defaultDishonouredPayment = agency.details.defaultDishonouredPayment;
        user.hasSalesDetails = getYesNo(agency.salesDetails && agency.salesDetails.agencyName);
        user.hasBillingDetails = getYesNo(agency.billingDetails && agency.billingDetails.email);
        user.hasPaymentMethods = getYesNo(agency.paymentMethod && agency.paymentMethod.hasAtLeast1EnabledPaymentMethod);
        user.hasActivityReportReceivers = getYesNo(
            agency.activityReportSettings &&
                agency.activityReportSettings.activityReportReceivers &&
                agency.activityReportSettings.activityReportReceivers.length
        );
        user.hasFollowUpReportReceivers = getYesNo(
            agency.followUpReportSettings &&
                agency.followUpReportSettings.followUpReportEmails &&
                agency.followUpReportSettings.followUpReportEmails.length
        );
        user.hasDefaultDocuments = getYesNo(agency.defaultDocuments && agency.defaultDocuments.length);
        user.hasDefaultLinks = getYesNo(
            agency.defaultTenantLinks && agency.defaultTenantLinks.links && agency.defaultTenantLinks.links.length
        );
        user.hasDefaultConnections = getYesNo(agency.connections && agency.connections.connectionsEnabled);
        user.hasSpecialConditions = getYesNo(agency.hasSpecialConditions, true);
        user.hasEmergencyContacts = getYesNo(agency.hasEmergencyContacts, true);
        user.hasSalesTemplates = getYesNo(agency.hasSalesTemplates, true);
        user.hasPmTemplates = getYesNo(agency.hasPmTemplates, true);
        user.hasPmAgreements = getYesNo(agency.hasPmAgreements, true);
        user.hasSalesAgreements = getYesNo(agency.hasSalesAgreements, true);
        user.hasUsedRenewalIntention = getYesNo(agency.hasUsedRenewalIntention, true);
        user.hasUsedFLKAKey = getYesNo(agency.hasUsedFLKAKey, true);
        user.hasUploadADoc = getYesNo(agency.hasUploadADoc, true);
        user.hasUploadADocTemplates = getYesNo(agency.hasUploadADocTemplates, true);
        user.hasBuildADoc = getYesNo(agency.hasBuildADoc, true);
        user.hasBuildADocTemplates = getYesNo(agency.hasBuildADocTemplates, true);
        user.countUsers = agency.countUsers;
        user.countPmAgreements = agency.countPmAgreements;
        user.countSalesAgreements = agency.countSalesAgreements;
        user.countLeaseAgreements = agency.countLeaseAgreements;
        user.countLeaseRenewals = agency.countLeaseRenewals;
    }
    return user;
};

const getIntegration = agency => {
    if (agency.integrations) {
        if (agency.integrations.propertyMe) {
            return 'PropertyMe';
        } else if (agency.integrations.propertyTree) {
            return 'PropertyTree';
        } else if (agency.integrations.managed) {
            return 'Managed';
        } else if (agency.integrations.sherlock) {
            return 'Sherlock';
        }
    }
    if (agency.externalServices?.listNow?.enabled) {
        return 'List Now';
    }
    return 'None';
};
