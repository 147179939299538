import {
    addDays,
    differenceInHours,
    differenceInDays,
    differenceInMonths,
    differenceInWeeks,
    differenceInYears,
    format,
    isBefore,
    isDate,
    isValid,
    parseISO,
    startOfDay,
    subMonths,
    subWeeks,
    subYears
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as React from 'react';
import {
    ACT_STATE,
    DATE_MONTH_FIRST_FORMAT_DATE,
    FORMAT_TIME_ONLY,
    NSW_STATE,
    NT_STATE,
    QLD_STATE,
    SA_STATE,
    STANDARD_DATE_DAY_FORMAT_DATE,
    STANDARD_DATE_DAY_FORMAT_WITH_TH,
    STANDARD_DATE_FORMAT,
    STANDARD_DATE_MONTH_FORMAT_DATE,
    STANDARD_DATE_TIME_FORMAT_DATE,
    TAS_STATE,
    TIMEZONE_ACT_STATE,
    TIMEZONE_LOADING,
    TIMEZONE_NSW_STATE,
    TIMEZONE_NT_STATE,
    TIMEZONE_NZ_AUCKLAND,
    TIMEZONE_NZ_CHATHAM,
    TIMEZONE_QLD_STATE,
    TIMEZONE_SA_STATE,
    TIMEZONE_TAS_STATE,
    TIMEZONE_VIC_STATE,
    TIMEZONE_WA_STATE,
    VIC_STATE,
    WA_STATE
} from '../config';
import { AvailableCountries, GENERAL_ACCOUNT_TYPE } from '../constants/constants';
import { calculateFinishDate } from './agreementUtils';

export const listOfTimezones = country => {
    //Timezones come from IANA time zone database
    const tzDataTimeZones = Intl.supportedValuesOf('timeZone');
    const timezones = [];

    if (country === AvailableCountries.NZ) {
        tzDataTimeZones.forEach(timeZone => {
            const timezoneItem = { value: timeZone, label: timeZone.replaceAll('_', '') };
            // We are filtering just for auckland and chatham currently
            if (timezoneItem.value === TIMEZONE_NZ_AUCKLAND || timezoneItem.value === TIMEZONE_NZ_CHATHAM) {
                timezones.push(timezoneItem);
            }
        });
    } else {
        tzDataTimeZones.forEach(timeZone => {
            const timezoneItem = { value: timeZone, label: timeZone.replaceAll('_', '') };
            // We are filtering just for Australia currently
            if (timezoneItem.value.includes('Australia')) {
                timezones.push(timezoneItem);
            }
        });
    }

    return timezones;
};

export const getTimeZoneForLocation = location => {
    if (location === QLD_STATE) {
        return TIMEZONE_QLD_STATE;
    } else if (location === VIC_STATE) {
        return TIMEZONE_VIC_STATE;
    } else if (location === NSW_STATE) {
        return TIMEZONE_NSW_STATE;
    } else if (location === ACT_STATE) {
        return TIMEZONE_ACT_STATE;
    } else if (location === NT_STATE) {
        return TIMEZONE_NT_STATE;
    } else if (location === SA_STATE) {
        return TIMEZONE_SA_STATE;
    } else if (location === TAS_STATE) {
        return TIMEZONE_TAS_STATE;
    } else if (location === WA_STATE) {
        return TIMEZONE_WA_STATE;
    }
};

/**
 *  Returns the date utcToZonedTime using the timezone for the state, in the case of a FLK GENERAL_ACCOUNT_TYPE
 *  we use the officeTimezone.
 * @param date
 * @param user
 * @returns {Date|*}
 */
export const getAgencyTimezoneDate = (date, user) => {
    const location = user.agency.details.location;

    if (date) {
        if (user.accountType === GENERAL_ACCOUNT_TYPE) {
            return utcToZonedTime(date, user.agency.details.officeTimezone);
        } else {
            return utcToZonedTime(date, getTimeZoneForLocation(location));
        }
    }
    return date;
};

/**
 * Get the timezone for the users location
 * for a FLK GENERAL_ACCOUNT_TYPE we return the timezone associated to a user.
 * @param user
 * @returns {string|string|*}
 */
export const getAgencyTimezoneFromUser = user => {
    // user === true means getting user details. so we return timezone as Australia/Sydney this will re-render after get user data
    if (user === true) {
        return TIMEZONE_LOADING;
    }
    if (user.accountType === GENERAL_ACCOUNT_TYPE) {
        return user.timezone;
    }

    const timezone = getTimeZoneForLocation(user.agency.details.location);

    if (!timezone) {
        throw new Error("Can't get timezone.");
    }
    return timezone;
};

/**
 * Get timezone from agency based on location for a FLK GENERAL_ACCOUNT_TYPE
 *  we return the officeTimezone.
 * @param agency
 * @returns {string|string}
 */
export const getAgencyTimezoneFromAgency = agency => {
    let timezone;

    if (agency.accountType === GENERAL_ACCOUNT_TYPE) {
        timezone = agency?.details?.officeTimezone;
    } else if (agency?.details?.location) {
        timezone = getTimeZoneForLocation(agency.details.location);
    }
    if (!timezone) {
        return TIMEZONE_NSW_STATE;
    }
    return timezone;
};

export function isTermGreaterThanThreeYears(startDate, endDate) {
    const diff = differenceInYears(endDate, startDate);
    if (diff > 3) {
        return true;
    }
    if (diff === 3) {
        const dateSubThreeYears = subYears(endDate, diff);
        const dateDiffDays = differenceInDays(dateSubThreeYears, startDate);
        if (dateDiffDays > 0) {
            return true;
        }
    }
    return false;
}

export function calculateEndDateForChange(values, form, fieldName) {
    let endDate = calculateFinishDate(values.period, values.startDate, values.qty);
    if (isValid(endDate)) {
        form.change(fieldName, endDate);
    }
}

export function calculateEndDate(values) {
    if (values.startDate) {
        let endDate = calculateFinishDate(values.period, values.startDate, values.qty);
        return endDate;
    }
}

const formatInFormat = (date, dateFormat) => {
    if (!date) {
        return '';
    }
    if (isDate(date) && isNaN(date.getTime())) {
        return '';
    }

    if (isDate(date)) {
        return format(date, dateFormat);
    }
    const parsedDate = parseISO(date);
    return format(parsedDate, dateFormat);
};

export function formatDateWithTimezoneAndFormat(date, timezone, format) {
    if (!date) {
        return '';
    }
    if (!timezone) {
        throw new Error('Timezone is required for format date');
    }
    if (timezone === TIMEZONE_LOADING) {
        return '...';
    }
    return formatInFormat(utcToZonedTime(date, timezone), format);
}
/**
 * Example: 12 January 2020
 * @param date
 * @returns {string|boolean}
 */
export function formatDateStandard(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, STANDARD_DATE_FORMAT);
}

/**
 * Example: 21 February 2020, 7:30 pm
 * @param date
 * @returns {string|boolean}
 */
export function formatDateTime(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, STANDARD_DATE_TIME_FORMAT_DATE);
}

/**
 * Example: 12 Jan 2020
 * @param date
 * @returns {string|boolean}
 */
export function formatDateShortMonth(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, STANDARD_DATE_MONTH_FORMAT_DATE);
}

/**
 * Adds the 0 at the start for day
 * Example: 07 January 2020
 * @param date
 * @returns {string|boolean}
 */
export function formatDateDay(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, STANDARD_DATE_DAY_FORMAT_DATE);
}
/**
 * Adds the th or nd at the end of the day
 * Example: 2nd of January 2020
 * @param date
 * @returns {string|boolean}
 */
export function formatDateDayWithTh(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, STANDARD_DATE_DAY_FORMAT_WITH_TH);
}

/**
 * Example: February 20 2019
 * @param date
 * @returns {string|boolean}
 */
export function formatDateDayMonthFirst(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, DATE_MONTH_FIRST_FORMAT_DATE);
}

/**
 * Example: 10:20 AM
 * @param date
 * @returns {string|boolean}
 */
export function formatTime(date, timezone) {
    return formatDateWithTimezoneAndFormat(date, timezone, FORMAT_TIME_ONLY);
}

export function getFormattedTermDays(startDate, endDate, timezone, startDateRaw, endDateRaw) {
    if (timezone === TIMEZONE_LOADING) {
        return '...';
    }
    if (
        startDate &&
        isDate(startDate) &&
        !isNaN(startDate.getTime()) &&
        endDate &&
        isDate(endDate) &&
        !isNaN(endDate.getTime())
    ) {
        let days = 'day';
        const diffAsDays = differenceInDays(endDate, startDate);
        if (diffAsDays === 0) {
            days = 'days';
        } else if (diffAsDays > 1) {
            days = 'days';
        }
        return (
            <React.Fragment>
                <strong>{`${diffAsDays} ${days}`}</strong> starting on{' '}
                <strong>{format(utcToZonedTime(startDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong> and ending on{' '}
                <strong>{format(utcToZonedTime(endDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <strong>Unknown</strong>
            </React.Fragment>
        );
    }
}

export function getFormattedTermWeeks(startDate, endDate, timezone, startDateRaw, endDateRaw) {
    if (timezone === TIMEZONE_LOADING) {
        return '...';
    }
    if (
        startDate &&
        isDate(startDate) &&
        !isNaN(startDate.getTime()) &&
        endDate &&
        isDate(endDate) &&
        !isNaN(endDate.getTime())
    ) {
        let days = '';
        let weeks = 'week';
        const diffAsWeeks = differenceInWeeks(endDate, startDate);
        const newDateMinusWeeks = subWeeks(endDate, diffAsWeeks);
        const daysOverWeek = differenceInDays(newDateMinusWeeks, startDate);

        if (diffAsWeeks === 0) {
            weeks = 'weeks,';
        } else if (diffAsWeeks > 1 && daysOverWeek === 0) {
            weeks = 'weeks';
        } else if (diffAsWeeks > 1 && daysOverWeek > 0) {
            weeks = 'weeks,';
        } else if (daysOverWeek > 0) {
            weeks = 'week,';
        }

        if (daysOverWeek > 0 && daysOverWeek <= 1) {
            days = `${daysOverWeek} day`;
        } else if (daysOverWeek > 1) {
            days = `${daysOverWeek} days`;
        }
        return (
            <React.Fragment>
                <strong>{`${diffAsWeeks} ${weeks} ${days}`}</strong> starting on{' '}
                <strong>{format(utcToZonedTime(startDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong> and ending on{' '}
                <strong>{format(utcToZonedTime(endDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <strong>Unknown</strong>
            </React.Fragment>
        );
    }
}

export function getFormattedTermMonths(startDate, endDate, timezone, startDateRaw, endDateRaw) {
    if (timezone === TIMEZONE_LOADING) {
        return '...';
    }
    if (
        startDate &&
        isDate(startDate) &&
        !isNaN(startDate.getTime()) &&
        endDate &&
        isDate(endDate) &&
        !isNaN(endDate.getTime())
    ) {
        let days = '';
        let months = 'month';
        let diffInDays = 0;
        const diffInMonths = differenceInMonths(endDate, startDate);

        if (diffInMonths >= 0) {
            const dateFromEnd = subMonths(endDate, diffInMonths);
            diffInDays = differenceInDays(dateFromEnd, startDate);
        }

        if (diffInMonths === 0) {
            months = 'months,';
        } else if (diffInMonths > 1 && diffInDays === 0) {
            months = 'months';
        } else if (diffInMonths > 1 && diffInDays > 0) {
            months = 'months,';
        } else if (diffInDays > 0) {
            months = 'month,';
        }

        if (diffInDays > 0 && diffInDays < 2) {
            days = `${diffInDays} day`;
        } else if (diffInDays >= 2) {
            days = `${diffInDays} days`;
        }
        return (
            <React.Fragment>
                <strong>{`${diffInMonths} ${months} ${days}`}</strong> starting on{' '}
                <strong>{format(utcToZonedTime(startDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong> and ending on{' '}
                <strong>{format(utcToZonedTime(endDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <strong>Unknown</strong>
            </React.Fragment>
        );
    }
}

export function getFormattedTermYears(startDate, endDate, timezone, startDateRaw, endDateRaw) {
    if (timezone === TIMEZONE_LOADING) {
        return '...';
    }
    if (
        startDate &&
        isDate(startDate) &&
        !isNaN(startDate.getTime()) &&
        endDate &&
        isDate(endDate) &&
        !isNaN(endDate.getTime())
    ) {
        let days = '';
        let years = 'year';
        const diffInYears = differenceInYears(endDate, startDate);
        const newDateMinusYear = subYears(endDate, diffInYears);
        const diffDays = differenceInDays(newDateMinusYear, startDate);

        if (diffInYears === 0) {
            years = 'years';
        } else if (diffInYears > 1 && diffDays === 0) {
            years = 'years';
        } else if (diffInYears > 1 && diffDays > 0) {
            years = 'years,';
        } else if (diffDays > 0) {
            years = 'year,';
        }

        if (diffDays === 1) {
            days = `${diffDays} day `;
        } else if (diffDays > 1) {
            days = `${diffDays} days `;
        }
        if (diffInYears > 1) {
            years = 'years';
        }
        return (
            <React.Fragment>
                <strong>{`${diffInYears} ${years} ${days}`}</strong> starting on{' '}
                <strong>{format(utcToZonedTime(startDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong> and ending on{' '}
                <strong>{format(utcToZonedTime(endDateRaw, timezone), STANDARD_DATE_FORMAT)}</strong>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <strong>Unknown</strong>
            </React.Fragment>
        );
    }
}

export function getTermDisplay(period, startDateRaw, endDateRaw, loggedInUser) {
    const startDate = startOfDay(utcToZonedTime(startDateRaw, getAgencyTimezoneFromUser(loggedInUser)));
    const endDate = startOfDay(utcToZonedTime(endDateRaw, getAgencyTimezoneFromUser(loggedInUser)));
    const endDateAddedDay = addDays(endDate, 1);
    switch (period) {
        case 'day':
            return getFormattedTermDays(
                startDate,
                endDateAddedDay,
                getAgencyTimezoneFromUser(loggedInUser),
                startDateRaw,
                endDateRaw
            );
        case 'week':
            return getFormattedTermWeeks(
                startDate,
                endDateAddedDay,
                getAgencyTimezoneFromUser(loggedInUser),
                startDateRaw,
                endDateRaw
            );
        case 'month':
            return getFormattedTermMonths(
                startDate,
                endDateAddedDay,
                getAgencyTimezoneFromUser(loggedInUser),
                startDateRaw,
                endDateRaw
            );
        case 'year':
            return getFormattedTermYears(
                startDate,
                endDateAddedDay,
                getAgencyTimezoneFromUser(loggedInUser),
                startDateRaw,
                endDateRaw
            );
        default:
            return getFormattedTermDays(
                startDate,
                endDateAddedDay,
                getAgencyTimezoneFromUser(loggedInUser),
                startDateRaw,
                endDateRaw
            );
    }
}

/**
 * @param {date} dateToCheck
 * @param {user} loggedInUser
 * @param {string} deliveryType
 * @returns {date}
 */
export function getDeliveryDate(dateToCheck, loggedInUser, deliveryType = null) {
    const currentDate = getAgencyTimezoneDate(new Date(), loggedInUser);
    if (!dateToCheck || isBefore(dateToCheck, currentDate) || deliveryType === 'email') {
        dateToCheck = currentDate;
    }
    return dateToCheck;
}

// Generic helper function to calculate the difference in hours between a date and time and the current time
export function getTimeDifferenceInHours(dateOfEntry, timeOfEntry) {
    if (!timeOfEntry || !/^\d{2}:\d{2}$/.test(timeOfEntry)) {
        return undefined; // Invalid time format
    }

    // Combine dateOfEntry and timeOfEntry to create a Date object
    const [hours, minutes] = timeOfEntry.split(':').map(Number);
    const entryDateTime = new Date(dateOfEntry);
    entryDateTime.setHours(hours, minutes, 0, 0); // Set the time part of the Date object

    // Calculate the difference in hours between now and the entry date + time
    return differenceInHours(entryDateTime, new Date());
}
