import React, { createContext, useContext, useEffect, useState } from 'react';
import cookie from 'react-cookie';
import { useSelector } from 'react-redux';
import { getIsUserLoggedIn } from '../../selectors/login';
import { getUserInfo } from '../../selectors/user';
import { useRollbarProvider } from './RollbarProvider';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { ACCOUNT_PROVIDER_FIX } from '../../constants/featureFlags';

const AccountContext = createContext(null);

export function AccountProvider({ children }) {
    const isFixEnabled = useFeatureFlag(ACCOUNT_PROVIDER_FIX, false);
    const Rollbar = useRollbarProvider();
    const isUserLoggedin = useSelector(getIsUserLoggedIn);
    const userInfo = useSelector(getUserInfo);
    const [accountType, setAccountType] = useState(cookie.load('accountType'));

    useEffect(() => {
        const accountTypeFromCookie = cookie.load('accountType');
        // this implementation can be removed when we're confident the fix doesn't break anything
        if (!isFixEnabled) {
            if (accountType && isUserLoggedin) {
                setAccountType(accountTypeFromCookie);
            }
        } else {
            // we only want to try and set this value if the user is logged in
            if (isUserLoggedin) {
                // we don't know why, but it's possible for this to be undefined, in that case try to fallback to redux
                if (accountTypeFromCookie) {
                    setAccountType(accountTypeFromCookie);
                } else if (!accountType && userInfo?.accountType) {
                    setAccountType(userInfo.accountType);
                } else if (!accountType) {
                    // this shouldn't ever happen, if it does hopefully this log can help figure out why
                    Rollbar.error('account type not able to be set from cookie or redux');
                }
            }
        }
    }, [accountType, isUserLoggedin, userInfo, isFixEnabled]);

    return <AccountContext.Provider value={{ accountType, setAccountType }}>{children}</AccountContext.Provider>;
}

export function useAccountProvider() {
    const { accountType, setAccountType } = useContext(AccountContext);

    useEffect(() => {
        const accountTypeCookie = cookie.load('accountType');
        if (accountTypeCookie) {
            setAccountType(accountTypeCookie);
        }
    }, [accountType]);

    return accountType;
}
